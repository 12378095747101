import React, { useRef, useState } from 'react';

import { MainTabs } from '../../components/routing/Styled';
import UploadedStageCompleted from './UploadedStageCompleted';
import UploadedStageDataValidated from './UploadedStageDataValidated';
import UploadedStageFetched from './UploadedStageFetched';
import UploadedStageFormatValidation from './UploadedStageFomatValdation';
import UploadedStageNew from './UploadedStageNew';

/**
 *
 *
 * @return {*}
 */
const UploadedStages = ({ load }) => {
  /** @type {*} */
  const [activeIndex, setActiveIndex] = useState(0);
  /** @type {*} */
  const toast = useRef(null);

  /** @type {*} */
  const items = [
    {
      label: 'New',
      icon: 'pi pi-user',
      command: (event) => {
        toast.current.show({
          severity: 'info',
          summary: 'Newly Imported Files',
          detail: 'New',
          life: 3000
        });
      }
    },
    {
      label: 'File Validation',
      icon: 'pi pi-user',
      command: (event) => {
        toast.current.show({
          severity: 'info',
          summary: 'File Validated Records',
          detail: event.item.label
        });
      }
    },
    {
      label: 'Data Validation',
      icon: 'pi pi-credit-card',
      command: (event) => {
        toast.current.show({
          severity: 'info',
          summary: 'Data Validated Records',
          detail: event.item.label
        });
      }
    },
    {
      label: 'Fetched',
      icon: 'pi pi-check-circle',
      command: (event) => {
        toast.current.show({
          severity: 'info',
          summary: 'Fetched Records',
          detail: event.item.label
        });
      }
    },
    {
      label: 'Completed',
      icon: 'pi pi-user',
      command: (event) => {
        toast.current.show({
          severity: 'success',
          summary: 'Completed Records',
          detail: event.item.label
        });
      }
    }
  ];
  // Stages Component
  const components = [
    <UploadedStageNew load={load} key={0} />,
    <UploadedStageFormatValidation load={load} key={1} />,
    <UploadedStageDataValidated load={load} key={2} />,
    <UploadedStageFetched load={load} key={3} />,
    <UploadedStageCompleted load={load} key={4} />
  ];
  // Active Stage Component
  const componentToRender = components[activeIndex] || components[4];
  return (
    <div className='steps-demo'>
      <div className='card'>
        <MainTabs
          model={items}
          activeIndex={activeIndex}
          onSelect={(e) => setActiveIndex(e.index)}
          readOnly={false}
        />
      </div>
      <div className='card'>{componentToRender}</div>
    </div>
  );
};

export default UploadedStages;
