import { PanelMenu } from 'primereact/panelmenu';
import { Tooltip } from 'primereact/tooltip';
import React, { useContext } from 'react';

import { subscriberContext } from '../../App';
import logoFull from '../../assets/images/logo.jpeg';
import { StyledLabel } from '../../containers/styled/Typography';
import { Routes } from '../routing/Routes.js';
import { SideWrapper } from './Styled';

/**
 *
 *
 * @return {*}
 */
const LeftSideBar = () => {
  /** @type {*} */
  // Data from APP.jsx
  const data = useContext(subscriberContext);
  // Icon and Title getting from Routes.js
  /** @type {*} */
  const sidebarPageIcon = Routes('sidebarPageIcon');
  /** @type {*} */
  const sidebarPageFull = Routes('sidebarPageFull');

  return (
    <SideWrapper className={data.sideBarFull ? 'sidebar' : ''}>
      <div
        style={{
          position: 'relative',
          left: `${data.sideBarFull ? '50px' : '84%'}`,
          top: `${data.sideBarFull ? '100px' : '115px'}`,
          cursor: 'pointer'
        }}
      >
        <Tooltip target='.onHover' position='top'>
          <div>{data.sideBarFull ? 'Expand' : 'Collapse'}</div>{' '}
        </Tooltip>
        <i
          className={`pi ${
            !data.sideBarFull
              ? 'pi-chevron-circle-left'
              : 'pi-chevron-circle-right'
          }  mr-2 onHover`}
          style={{ fontSize: '1.1rem' }}
          onClick={() => {
            data.setSideBarFull(!data.sideBarFull);
          }}
        />
      </div>
      <div className='mb-5 flex justify-content-center'>
        <img
          src={logoFull}
          alt=''
          width={!data.sideBarFull ? '65px' : '50px'}
        />
      </div>
      <StyledLabel className='ml-2'>Menu</StyledLabel>
      <PanelMenu
        className='mt-2 custom-panelmenu'
        model={!data.sideBarFull ? sidebarPageFull : sidebarPageIcon}
      />
    </SideWrapper>
  );
};

export default LeftSideBar;
