import './books.css';

import { Chip } from 'primereact/chip';
import { Fieldset } from 'primereact/fieldset';
import { useEffect, useState } from 'react';

import categoryImg from '../../assets/png/category.png';
import { StyledLabelBlack } from '../../components/routing/Styled';

const Category = ({ productDetail }) => {
  const [categoryData, setCategoryData] = useState([]);
  const [classificationData, setClassificationData] = useState({});
  const [classificationDataValue, setClassificationDataValue] = useState([]);
  const [classificationDataLabel, setClassificationDataLabel] = useState([]);
  const [allow, setAllow] = useState(false);

  const content = [
    {
      name: 'Category Flow',
      value: `${categoryData[0]?.displayNameFlattened || 'NA'}`
    },
    { name: 'Parent', value: `${categoryData[0]?.parent?.name || 'NA'}` }
  ];
  useEffect(() => {
    fetch(
      process.env.REACT_APP_APIPRODUCTURL +
        `/v1/categories/source/AMZ/product/${productDetail?.primaryId?.id}`,
      {
        headers: {
          Accept: 'application/json;charset=UTF-8'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setCategoryData(data?.categories);
        setClassificationData(data?.classifications);
        data && setAllow(true);
      })
      .catch(() => {
        // Handle any errors here
        // console.error('Error fetching dataTypeOption:', error);
        setAllow(false);
      });
  }, [productDetail?.primaryId?.id]);
  // Legend Template
  const legendTemplate = (title, icon) => {
    return (
      <div className='flex align-items-center'>
        <span className='mr-2'>
          <img src={icon} alt='' />
        </span>
        <span>{title}</span>
      </div>
    );
  };

  // Genre
  const getGenre = () => {
    return productDetail?.classifications?.genre?.map((ele) => {
      return <Chip key={ele} label={ele} className='ml-1 mr-1 mb-1' />;
    });
  };
  // Get Classifications Value
  const getClassificationsValue = (ele) => {
    return ele?.map((ele) => {
      return <Chip key={ele} label={ele} className='ml-1 mr-1 mb-1' />;
    });
  };
  useEffect(() => {
    // GetClassification in array
    const getClassArray = () => {
      const tempArray = [];
      const tempArrayLabel = [];
      setClassificationDataValue([]);
      setClassificationDataLabel([]);
      for (const classification in classificationData) {
        tempArray.push(classificationData[classification]);
        tempArrayLabel.push(classification);
      }
      setClassificationDataValue(tempArray);
      setClassificationDataLabel(tempArrayLabel);
    };
    getClassArray();
  }, [classificationData]);

  return (
    <div className='pr-3'>
      <Fieldset
        legend={legendTemplate('Category Details', categoryImg)}
        style={{ minHeight: '100px', overflowY: 'auto', height: '400px' }}
      >
        <>
          {' '}
          <div className='p-3 grid'>
            {content.map((val) => {
              return (
                <div className='md:col-6 col-12' key={val.name}>
                  <StyledLabelBlack>{val.name}</StyledLabelBlack>
                  <div className='mt-1'>{val.value}</div>
                </div>
              );
            })}
          </div>
          <div className='p-3 grid'>
            {allow && productDetail?.classifications?.genre?.length !== 0 && (
              <div className='md:col-12 lg:col-12 sm:col-12'>
                <Fieldset
                  legend={legendTemplate('Genre')}
                  style={{ minHeight: '100px' }}
                >
                  {getGenre()}
                </Fieldset>
              </div>
            )}
            {classificationDataValue?.map((ele, index) => {
              return (
                <div key={index} className='md:col-4 lg:col-4 sm:col-12'>
                  <Fieldset
                    legend={legendTemplate(classificationDataLabel[index])}
                    style={{ minHeight: '100px' }}
                  >
                    {getClassificationsValue(ele)}
                  </Fieldset>
                </div>
              );
            })}
          </div>
        </>
      </Fieldset>
    </div>
  );
};
export default Category;
