import { filter } from "lodash";
import { Badge } from "primereact/badge";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { useCallback, useEffect, useRef, useState } from "react";

import {
  loading as Loading,
  loadingdrill as LoadingDrill,
} from "../../components/generic/loading";
import {
  StyledLabelBlack,
  PanelHeading,
} from "../../components/routing/Styled";
// import { PanelHeading } from '../../components/routing/Styled';
import { StageLabel } from "../../containers/Styled";
import Pagination from "../books/Pagination";
import { exportData } from "./download";

/**
 *
 *
 * @return {*}
 */
const ALLImportedFiles = ({ load }) => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [lastPage, setLastPage] = useState(1);
  const [pageErr, setPageErr] = useState(1);
  const [sizeErr, setSizeErr] = useState(5000);
  const [lastPageErr, setLastPageErr] = useState(1);
  /** @type {*} */
  const sizeList = [
    { name: 20, value: 20 },
    { name: 30, value: 30 },
    { name: 40, value: 40 },
  ];
  /** @type {*} */
  const sizeListErr = [
    { name: 1000, value: 1000 },
    { name: 5000, value: 5000 },
    { name: 10000, value: 10000 },
    { name: 50000, value: 50000 },
    { name: 100000, value: 100000 },
    { name: 500000, value: 500000 },
  ];
  /** @type {*} */
  const [selectedRow, setSelectedRow] = useState(null);
  /** @type {*} */
  const [expandedRows, setExpandedRows] = useState([]);
  /** @type {*} */
  const [costData, setCostData] = useState([]);
  /** @type {*} */
  const [drilldownData, setDrilldownData] = useState([]);
  /** @type {*} */
  // Loading Screen variable
  const [loading, setLoading] = useState(false);
  /** @type {*} */
  const [loadingDrilldown, setLoadingDrilldown] = useState(false);
  /** @type {*} */
  // UseRef for toast
  const toast = useRef(null);

  // Fetch Uploaded Data
  const fetchCostData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetch(
        process.env.REACT_APP_APIIMPORTURL +
          `/v1/requests/import?page=${page - 1}&size=${size}`,
        {
          headers: {
            Accept: "application/json;charset=UTF-8",
          },
        }
      );
      const data = await response.json();
      setLoading(false);
      setLastPage(data?.page?.totalPages);
      const updatedData = data._embedded.responseVOList;
      const filteredData = filter(updatedData);
      setCostData(filteredData);
    } catch (error) {
      console.error("Error fetching cost data:", error);
    }
  }, [page, size]);

  useEffect(() => {
    setSelectedRow(null);
  }, [load]);

  /** @type {*} */
  // Fetch Data for Drilldown Table
  const fetchDrilldownData = useCallback(async () => {
    if (selectedRow) {
      setLoadingDrilldown(true);
      try {
        const response = await fetch(
          process.env.REACT_APP_APIIMPORTURL +
            "/v1/requests/" +
            selectedRow.id +
            `/items/error?page=${pageErr - 1}&size=${sizeErr}`,
          {
            headers: {
              Accept: "application/json;charset=UTF-8",
            },
          }
        );
        const data = await response.json();
        const updatedData = data._embedded.importRequestItemVOList;
        const filteredData = filter(updatedData, (item) => item?.error);
        setDrilldownData(filteredData);
        setLoadingDrilldown(false);
        setLastPageErr(data?.page?.totalPages);
      } catch (error) {
        console.error("Error fetching drilldown data:", error);
      }
    }
  }, [selectedRow, pageErr, sizeErr]);

  useEffect(() => {
    fetchDrilldownData();
  }, [fetchDrilldownData]);

  useEffect(() => {
    fetchCostData();
  }, [fetchCostData, load]);

  // File Name Body
  const fileNameBody = (rowData) => {
    const isExpanded = expandedRows?.[rowData.id];
    const failCount =
      (rowData?.stats?.val?.failureCount || 0) +
      (rowData?.stats?.fetch?.failureCount || 0) +
      (rowData?.stats?.sync?.failureCount || 0);
    return (
      <div>
        {(rowData?.stats?.val?.successCount > 0 ||
          rowData?.stats?.val?.failureCount > 0 ||
          rowData?.stats?.fetch?.failureCount > 0 ||
          rowData?.stats?.sync?.failureCount > 0) && (
          <button
            className="p-link p-datatable-row-toggler"
            onClick={() => handleRowToggle(rowData)}
          >
            <i
              className={`pi ${
                isExpanded ? "pi-chevron-down" : "pi-chevron-right"
              }`}
            />
          </button>
        )}
        <span>{rowData?.requestFile?.originalFileName}</span>
      </div>
    );
  };
  // Total records body
  const totalRecordsBody = (rowData) => {
    return <Badge value={rowData?.stats?.totalCount || 0} severity="info" />;
  };
  // Success Count Body
  const successCountBody = (rowData) => {
    return (
      <Badge
        value={rowData?.stats?.sync?.successCount || 0}
        severity="success"
      />
    );
  };
  // Body for FailCount
  const failCountBody = (rowData) => {
    const failcount =
      (rowData?.stats?.val?.failureCount || 0) +
      (rowData?.stats?.fetch?.failureCount || 0) +
      (rowData?.stats?.sync?.failureCount || 0);
    return <Badge value={failcount} severity="danger" />;
  };
  // Time Taken body
  const timetakenBody = (rowData) => {
    const timeTaken = rowData?.stats?.sync?.timeTaken;
    const unit = timeTaken >= 60000 ? "mins" : "min";
    const formattedTime = timeTaken
      ? `${(timeTaken / 60000).toFixed(0)} ${unit}`
      : "";
    return (
      <span>
        {rowData?.stats?.sync?.timeTaken
          ? (rowData?.stats?.sync?.timeTaken / 60000)?.toFixed(0)
          : ""}
        {formattedTime}
      </span>
    );
  };
  // Processing Status Body
  const processingStatusBody = (rowData) => {
    return (
      <StageLabel className={`stage-badge status-${rowData?.processingStatus}`}>
        {rowData.processingStatus}
      </StageLabel>
    );
  };

  // Processing Status Body
  const requestTypeBody = (rowData) => {
    const requestType =
      rowData.requestType === "REFRESH_DATA"
        ? "Fetch"
        : rowData.requestType === "GENERATE_REPORT"
        ? "Generate Report"
        : rowData.requestType === "REFRESH_DATA_AND_GENERATE_REPORT"
        ? "Fetch & Generate Report"
        : "";
    return (
      <span>
        {requestType} ({rowData.marketplace})
      </span>
    );
  };
  // Processing Status Body
  const fieldGroupBody = (rowData) => {
    const fieldGroup =
      rowData.fieldGroup === "GROUP_ALL"
        ? "ALL"
        : rowData.fieldGroup === "GROUP_METADATA"
        ? "Meta data"
        : rowData.fieldGroup === "GROUP_DIMENSIONS"
        ? "Dimensions (Product and Package)"
        : rowData.fieldGroup === "GROUP_RANKING"
        ? "Ranking (ALL)"
        : rowData.fieldGroup === "GROUP_RANKING_BOOKS"
        ? "Ranking (Books) "
        : rowData.fieldGroup === "GROUP_OFFERS"
        ? "Pricing (BuyBox and Lowest)"
        : rowData.fieldGroup === "GROUP_IMAGES"
        ? "Images "
        : rowData.fieldGroup === "GROUP_BB_OFFERS"
        ? "Pricing (BuyBox)"
        : rowData.fieldGroup === "GROUP_LP_OFFERS"
        ? "Pricing (Lowest)"
        : "";
    return <span>{fieldGroup}</span>;
  };

  /** @type {*} */
  // Columns for Upload Table
  const columns = [
    {
      field: "requestFile.originalFileName",
      header: "File Name",
      expander: true,
      body: fileNameBody,
    },
    {
      field: "requestFile.requestType",
      header: "Request Type",
      body: requestTypeBody,
    },
    {
      field: "requestFile.fieldGroup",
      header: "Fields",
      body: fieldGroupBody,
    },
    {
      field: "stats.totalCount",
      header: "Total Records",
      body: totalRecordsBody,
    },
    {
      field: "stats.val.successCount",
      header: "Success Count",
      body: successCountBody,
    },
    {
      field: "stats.val.failureCount",
      header: "Fail Count",
      body: failCountBody,
    },
    /*
    {
      field: 'stats.stats.totalProcessingTime',
      header: 'Time Taken',
      body: timetakenBody
    },
    */
    {
      field: "processingStatus",
      header: "Processing Status",
      body: processingStatusBody,
    },
  ];

  /**
   *
   *
   * @param {*} rowData
   * @return {*}
   */
  // Expandable Body Template
  const expandableBodyTemplate = (rowData) => {
    if (rowData.stats) {
      return (
        <div
          style={{ padding: "0 5rem 0 5rem" }}
          className="flex justify-content-between align-items-center"
        >
          <div>
            <StyledLabelBlack>Validated Success:</StyledLabelBlack>
            <span>
              <Badge
                severity="success"
                className="ml-2"
                value={rowData?.stats?.val?.successCount || 0}
              />
            </span>
          </div>
          <div>
            <StyledLabelBlack>Validated Fail:</StyledLabelBlack>
            <span className="ml-2">
              <Badge
                severity="danger"
                className="ml-2"
                value={rowData?.stats?.val?.failureCount || 0}
              />
            </span>
          </div>

          <div>
            <StyledLabelBlack>Fetch Success:</StyledLabelBlack>
            <span>
              <Badge
                severity="success"
                className="ml-2"
                value={rowData?.stats?.fetch?.successCount || 0}
              />
            </span>
          </div>

          <div>
            <StyledLabelBlack>Fetch Fail:</StyledLabelBlack>
            <span>
              <Badge
                severity="danger"
                className="ml-2"
                value={rowData?.stats?.fetch?.failureCount || 0}
              />
            </span>
          </div>

          <div>
            <StyledLabelBlack>Sync Success:</StyledLabelBlack>
            <span>
              <Badge
                severity="success"
                className="ml-2"
                value={rowData?.stats?.sync?.successCount || 0}
              />
            </span>
          </div>

          <div>
            <StyledLabelBlack>Sync Fail:</StyledLabelBlack>
            <span>
              <Badge
                severity="danger"
                className="ml-2"
                value={rowData?.stats?.sync?.failureCount || 0}
              />
            </span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  /**
   *
   *
   * @param {*} rowData
   */
  // Toggle for Expansion And Collapse
  const handleRowToggle = (rowData) => {
    const expandedRowsData = { ...expandedRows };
    if (expandedRowsData[rowData.id]) {
      delete expandedRowsData[rowData.id]; // Collapsing the row
    } else {
      expandedRowsData[rowData.id] = true; // Expanding the row
    }
    setExpandedRows(expandedRowsData);
  };
  /** @type {*} */
  // Columns For Drilled Columns
  const drilledColumns = [
    {
      field: "type",
      header: "Type",
    },
    {
      field: "id",
      header: "ID",
    },
    {
      field: "marketplace",
      header: "Market Place",
    },
    {
      field: "remarks",
      header: "Remarks",
    },
  ];

  /**
   *
   *
   * @param {*} event
   */
  // OnRow Click function to trigger respective drilldown
  const onRowClick = (event) => {
    const failcount =
      (event.data.stats?.val?.failureCount || 0) +
      (event.data.stats?.fetch?.failureCount || 0) +
      (event.data.stats?.sync?.failureCount || 0);
    // event.data.stats?.val?.failureCount
    failcount
      ? setSelectedRow(event.data)
      : toast.current.show({
          severity: "error",
          summary: "No Failed Records",
          detail: "There is no Failed Records",
          life: 3000,
        });
  };

  /** @type {*} */
  const exportButtonRef = useRef(null);

  /**
   *
   *
   * @param {*} drilldownData
   * @param {*} datavalidexport
   */
  const exportDetail = (drilldownData, datavalidexport) => {
    if (drilldownData.length !== 0) {
      const formattedData = drilldownData.map((item) => {
        // Format the data as needed for Excel export, for example:
        return {
          Type: item.type,
          ID: item.id,
          Marketplace: item.marketplace,
          Status: item.status,
          Remarks: item.remarks,
          // Add more fields as needed
        };
      });

      exportData(drilldownData, datavalidexport, formattedData);
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      {!loading && (
        <>
          {" "}
          <DataTable
            value={costData}
            rowExpansionTemplate={expandableBodyTemplate}
            expandedRows={expandedRows}
            onRowToggle={(e) => handleRowToggle(e.data)}
            dataKey="id"
            rows={15}
            selectionMode="single"
            selection={selectedRow}
            onSelectionChange={(e) => setSelectedRow(e.value)}
            onRowClick={onRowClick}
            paginatorTemplate="CurrentPageReport
        FirstPageLink
        PrevPageLink
        PageLinks
        NextPageLink
        LastPageLink
        RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            rowsPerPageOptions={[15, 20, 30]}
          >
            {columns.map((col) => (
              <Column
                key={col.field}
                field={col.field}
                header={col.header}
                expander={col.expander}
                body={col.body}
                sortable
              />
            ))}
          </DataTable>{" "}
          <Pagination
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            lastPage={lastPage}
            sizeList={sizeList}
          />
        </>
      )}
      {loading && <Loading />}
      {(selectedRow?.stats?.val?.failureCount > 0 ||
        selectedRow?.stats?.fetch?.failureCount > 0 ||
        selectedRow?.stats?.sync?.failureCount > 0) && (
        <div>
          <PanelHeading className="p-d-flex p-ai-center text-color text-base font-medium">
            <span>
              <i
                ref={exportButtonRef}
                title="Export All"
                className="pi pi-file-excel mr-2"
                style={{ fontSize: "inherit !important" }}
              />
              Failed Records Details
              <i
                ref={exportButtonRef}
                title="Export All"
                className="pi pi-file-export ml-3 text-red-500 text-lg cursor-pointer "
                onClick={() => exportDetail(drilldownData, "datavalidexport")}
                style={{ fontSize: "inherit !important" }}
              />
            </span>
          </PanelHeading>
          {drilldownData && !loadingDrilldown && (
            <>
              {" "}
              <DataTable
                value={drilldownData}
                dataKey="id"
                rows={5000}
                //              paginator
                paginatorTemplate="CurrentPageReport
          FirstPageLink
          PrevPageLink
          PageLinks
          NextPageLink
          LastPageLink
          RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                rowsPerPageOptions={[
                  1000, 5000, 10000, 20000, 50000, 100000, 500000, 1000000,
                ]}
              >
                {drilledColumns.map((col) => (
                  <Column
                    key={col.field}
                    field={col.field}
                    header={col.header}
                    sortable
                  />
                ))}
              </DataTable>{" "}
              <Pagination
                page={pageErr}
                setPage={setPageErr}
                size={sizeErr}
                setSize={setSizeErr}
                lastPage={lastPageErr}
                sizeList={sizeListErr}
              />
            </>
          )}
          {loadingDrilldown && <LoadingDrill />}
        </div>
      )}
    </div>
  );
};

export default ALLImportedFiles;
