import moment from 'moment';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';

import { StyledDataTable } from '../Styled';

const BookTable = ({ data, columns, filters, setTableData }) => {
  const [dataForTable, setDataForTable] = useState(data);
  const [authorDataForTable, setAuthorDataForTable] = useState(data);
  const [finalDataForTable, setFinalDataForTable] = useState(data);
  const [categoryValue, setCategoryValue] = useState('');
  const [authorValue, setAuthorValue] = useState('');
  const [lastRefValue, setLastRefValue] = useState('');

  const onCategoryChange = (e) => {
    setDataForTable(authorDataForTable);
    const value = e.target.value;
    setCategoryValue(value);
    let checking = false;
    const filterData = authorDataForTable.filter((ele, index1) => {
      ele.categories.forEach((check, index3) => {
        checking = check.value.toLowerCase().includes(value.toLowerCase());
      });
      return ele.categories.length === 0 ? false : checking;
    });
    setDataForTable(filterData);
    setFinalDataForTable(filterData);
    value === '' && setDataForTable(authorValue ? authorDataForTable : data);
    value === '' &&
      setFinalDataForTable(authorValue ? authorDataForTable : data);
  };
  // Author Change
  const onAuthorChange = (e) => {
    setAuthorDataForTable(dataForTable);
    const value = e.target.value;
    setAuthorValue(value);
    let checking = false;
    const filterData = dataForTable.filter((ele, index1) => {
      ele.contributors.forEach((check, index3) => {
        checking =
          check.role === 'AUTHOR' &&
          check.person.toLowerCase().includes(value.toLowerCase());
      });
      return ele.contributors.length === 0 ? false : checking;
    });
    setAuthorDataForTable(filterData);
    setFinalDataForTable(filterData);
    value === '' && setAuthorDataForTable(categoryValue ? dataForTable : data);
    value === '' && setFinalDataForTable(categoryValue ? dataForTable : data);
  };
  // Last Refreshed Change
  const onLastRefChange = (e) => {
    const value = e.target.value;
    setLastRefValue(value);
    let checking = false;
    const filterData = authorDataForTable.filter((ele, index1) => {
      const dateMoment = moment(ele?.lastRefreshed.metadata);
      checking = dateMoment.format('DD MMM YYYY HH:mm:ss').includes(value);

      return checking;
    });
    setFinalDataForTable(filterData);
    value === '' &&
      setAuthorDataForTable(authorValue ? authorDataForTable : data);
    value === '' &&
      setFinalDataForTable(authorValue ? authorDataForTable : data);
  };
  // category filter
  const categoryFilter = (options) => {
    return (
      <InputText
        value={categoryValue}
        onChange={onCategoryChange}
        placeholder='Search'
      />
    );
  };
  // Author Filter
  const authorFilter = (options) => {
    return (
      <InputText
        value={authorValue}
        onChange={onAuthorChange}
        placeholder='Search'
      />
    );
  };
  // Last Refreshed Filter
  const lastRefFilter = (options) => {
    return (
      <InputText
        value={lastRefValue}
        onChange={onLastRefChange}
        placeholder='Search'
      />
    );
  };
  useEffect(() => {
    setFinalDataForTable(data);
  }, [data]);
  useEffect(() => {
    setTableData(
      finalDataForTable?.length >= data?.length
        ? data
        : finalDataForTable || data
    );
  }, [dataForTable, data, authorDataForTable]); //eslint-disable-line
  // Filter Element
  const getFilterElement = (column) => {
    if (column.header === 'Categories') {
      return categoryFilter;
    } else if (column.header === 'Author') {
      return authorFilter;
    } else if (column.header === 'Last Refreshed') {
      return lastRefFilter;
    } else {
      return null;
    }
  };
  return (
    <StyledDataTable
      value={
        finalDataForTable?.length >= data?.length
          ? data
          : finalDataForTable || data
      }
      dataKey='id'
      rows={10}
      rowsPerPageOptions={[10, 20, 30]}
      currentPageReportTemplate='Showing {first} to {last} of {totalRecords} records'
      responsiveLayout='scroll'
      filters={filters}
      filterDisplay='row'
    >
      {columns.map((column) => {
        return (
          <Column
            key={column.id}
            field={column.field}
            header={column.header}
            body={column.body}
            sortable={column.id !== 'image' && column.id !== 'author'}
            showFilterMenu={false}
            filterMenuStyle={{ width: '8rem' }}
            style={{ maxWidth: '8rem' }}
            filter={
              column.header !== 'Image' && column.header !== 'Contributors'
            }
            filterPlaceholder='Search'
            filterElement={getFilterElement(column)}
          />
        );
      })}
    </StyledDataTable>
  );
};
export default BookTable;
