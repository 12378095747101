import './loading.css';

/**
 *
 *
 * @return {*}
 */
// Loading For Drilldown Table
export const loading = () => {
  return (
    <div className='wrap pos-down'>
      <div className='bounceball' />
      <div className='text'>LOADING...</div>
    </div>
  );
};

/**
 *
 *
 * @return {*}
 */
// Loading For Drilldown Table
export const loadingdrill = () => {
  return (
    <div className='wrap'>
      <div className='bounceball' />
      <div className='text'>LOADING...</div>
    </div>
  );
};
export default loading;
