import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { Menu } from 'primereact/menu';
import { Steps } from 'primereact/steps';
import { TreeSelect } from 'primereact/treeselect';
import styled from 'styled-components';

import logo from '../../assets/images/ALinea_logo_no_line_purple.webp';

export const AppWrapper = styled.div`
  font-family: ${(props) => props.theme.font.family};
`;

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const SideWrapper = styled.aside`
  width: ${(props) => props.theme.sizes.sidemenu};
  padding: 5px 0px 5px 0px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  clip-path: inset(0px -15px 0px 0px);
  ul li {
    transition: all 0.7s;
  }
`;
export const UserAvatar = styled.img`
  width: 32px;
  height: 32px;
  border: 1px solid lightgray;
`;
export const ContentArea = styled.section`
  background-color: #f8f7f7;
  width: 100%;
`;

export const InfoWrapper = styled.div`
  padding: 8px 15px;
  height: 45px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  clip-path: inset(0px 0 -15px 0px);
  background-color: white;
`;

export const FooterWrapper = styled.div`
  padding: 12px 15px;
  height: 30px !important;
  clip-path: inset(0px 0 -15px 0px);
  background-color: white;
  align-items: center;
  margintop: 8px;
  position: absolute;
  z-index: 100;
  bottom: 0px;
`;

export const FooterLogo = styled.div`
  width: 50px;
  height: 13px;
  display: inline-block;
  background: url(${logo});
  background-size: cover;
`;

export const TitleWrapper = styled.div`
  padding: 16px 16px;
  height: 90px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  clip-path: inset(0px 0 -15px 0px);
  background-color: #bc3232;
  color: white;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
`;

export const InnerContent = styled.div`
  background-color: #f5f6fa;
  z-index: 5;
  position: relative;
  // width: -webkit-fill-available;
  margin-top: -39px;
  background: #fff;
  // width: 97.3%;
  width: calc(100vw - 102px);
  margin-left: 16px;
  border-radius: 20px;
  height: calc(100vh - 86px);
  overflow-y: auto;
  box-shadow: ${(props) => props.theme.shadow.standard};
`;

export const MenuList = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
  li {
    padding: 10px 0;
    &:first-child {
      padding-top: 0;
      button a img {
        width: 32px;
      }
    }
  }
`;

export const CardLayout = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.radius.sizes.custom};
  // min-height: 620px;
  height: 97%;
  padding: ${(props) => props.theme.font.sizes.standard};
  padding-top: 0px;
`;

export const CustomDropdown = styled(Dropdown)`
  .p-dropdown-label.p-inputtext {
    padding: 8px;
  }
`;

export const LinkButton = styled(Button)`
  background-color: transparent;
  padding: 0;
  margin: 0 auto;
  min-width: auto;

  &:hover,
  &:focus {
    background: transparent !important;
  }

  &:enabled {
    &:active {
      background: transparent !important;
    }
  }
`;

export const AccountMenu = styled(Menu)`
  .p-submenu-header {
    display: none;
  }
  .p-menuitem-link {
    box-shadow: none !important;
  }
`;
export const CardBookFilter = styled.div`
 
  padding: 2px;
  // background-color: #e5d9d9 !important;
  height: auto;
`;
export const CardWithBackground = styled.div`
  border: 1px solid #c6c3c3;
  border-radius: 8px 8px 0px 0px;
  padding: 12px;
  background-color: #e5d9d9 !important;
`;
export const CardNoBackground = styled.div`
  border: 1px solid #c6c3c3;
  border-radius: 0px 0px 8px 8px;
  padding: 5px;
  width: -webkit-fill-available;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
`;
export const StyledLabelBlack = styled.label`
  color: black;
  font-weight: 600;
`;
// Custom Card for Upload Filters
export const StyledCustomCard = styled.div`
  min-height: 25vh;
  max-height: 60vh;
  margin-bottom: 40px;
`;

// Custom Card for Upload Filters
export const StyledSwitchButton = styled(InputSwitch)`
  .p-inputswitch-slider {
    background: "rgb(233 36 36 / 36%) !important";
  }

  .p-inputswitch-slider:before {
    background: #e92424 !important;
  }
  .flex.sm\:col-12.md\:col-5.lg\:col-4.align-items-center.lg\:justify-content-end.sm\:justify-content-start {
    padding: 20px !important;
  }
`;
// Stepper - Import Screen Styling
export const MainTabs = styled(Steps)`
  ul:before {
    position: absolute;
    left: 9%;
    top: 48px;
    margin-top: 0;
    width: 80%;
    content: " ";
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
  @media screen and (max-width: 768px) {
    ul {
      z-index: 2;
      flex-direction: column;
      &:before {
        height: 78%;
        width: 2px;
        left: 50%;
        z-index: 1;
        background: lightgray;
      }
    }
  }
  .p-steps-item::before {
    content: " ";
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    width: 100%;
    top: 50%;
    left: 0;
    display: block;
    position: absolute;
    margin-top: -1rem;
    border: none;
  }
  .p-steps-item:last-child {
    flex-grow: 1;
  }
  a.p-menuitem-link {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column !important;
  }

  .p-steps-number {
    display: none;
  }

  .p-steps-item .p-menuitem-link > span.p-menuitem-icon {
    background-color: rgb(133 114 114 / 74%);
    color: #ffffff;
    border: 1px solid transparent;
    min-width: 2rem;
    line-height: 2rem;
    z-index: 1;
    border-radius: 50%;
    justify-content: center;
    display: flex;
    max-width: 2rem;
  }
  .p-steps-item[aria-selected="true"] .p-menuitem-link > span.p-menuitem-icon,
  .p-steps-item .p-menuitem-link:not(.p-disabled):focus .p-menuitem-icon,
  .p-steps-item .p-menuitem-link:not(.p-disabled):focus:hover .p-menuitem-icon {
    background-color: rgb(188 50 50);
  }
  .p-steps-item .p-menuitem-link > span.p-steps-title {
    color: rgb(136 118 118 / 82%);
    z-index: 999;
  }

  .p-steps-item[aria-selected="true"] .p-menuitem-link > span.p-steps-title,
  .p-steps-item .p-menuitem-link:not(.p-disabled):focus .p-steps-title {
    color: #bc3232d6;
  }
  .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
    background: none;
  }
`;

// Tabs Conatiner Block - Import Screen
export const TabGridContainer = styled.div`
  border: solid rgba(0, 0, 0, 0.12);
  border-width: 0 0 1px 0;
  .p-tabmenu-nav {
    border: none;
  }
`;

// Drilldown Table Container blocks
export const PanelHeading = styled.div`
  background-color: #f8fafc;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
  border-top: 1px solid #e2e8f0;
  margin: 15px 0px 1px 0px;
  padding: 15px;
  // font-size: 18px;
  // font-weight: 500;
  // i{
  //   font-size: inherit !important;
  // }
`;

// Product Page Column Container Block
export const StyleTreeSelect = styled(TreeSelect)`
  &.p-inputwrapper-filled.p-treeselect.p-treeselect-chip .p-treeselect-label {
    padding: 0.3rem 0.5rem !important;
    height: auto !important;
  }
`;
// Product Details Card
export const ProductCardWithBackground = styled.div`
  border: 1px solid #c6c3c3;
  border-radius: 8px 8px 0px 0px;
  padding: 12px;
  background-color: #e5d9d9 !important;
  width: 400px !important;
`;

export const ProductCardNoBackground = styled.div`
  border: 1px solid #c6c3c3;
  border-radius: 0px 0px 8px 8px;
  padding: 12px;
  width: 400px;
  height: auto;
`;
