import { OptionMainTabMenuStyle, OptionTabMenuStyle } from './Styled';

/**
 *
 *
 * @param {*} { tabs, activeTab, setActiveTab }
 * @return {*}
 */

const OptionTab = ({ tabs, activeTab, setActiveTab, sectionRefs, number }) => {
  //  Scroll Function
  const scrollToSection = (sectionRef) => {
    if (sectionRef.current) {
      const offsetTopVal = sectionRef.current.offsetTop;
      sectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
        offsetTop: offsetTopVal
      });
    }
  };
  return (
    <>
      {/* Product Detail Tab */}
      {number === '1' && (
        <OptionTabMenuStyle
          model={tabs}
          activeIndex={activeTab}
          onTabChange={(e) => {
            setActiveTab(e.index);
            scrollToSection(sectionRefs[e.index]);
          }}
        />
      )}
      {/* Import and Upload Tab */}
      {number === '2' && (
        <OptionMainTabMenuStyle
          model={tabs}
          activeIndex={activeTab}
          onTabChange={(e) => {
            setActiveTab(e.index);
          }}
        />
      )}
    </>
  );
};
export default OptionTab;
