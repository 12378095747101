import moment from 'moment';
import { Fieldset } from 'primereact/fieldset';
import { useEffect, useState } from 'react';

import audience from '../../assets/png/audience.png';
import book from '../../assets/png/book.png';
import manufacture from '../../assets/png/manufactured.png';
import { StyledLabelBlack } from '../../components/routing/Styled';

const ItemDesc = ({ productDetail }) => {
  const [tableContent, setTableContent] = useState([]);
  const [tableContentAudience, setTableContentAudience] = useState([]);
  const [tableContentManufacturer, setTableContentManufacturer] = useState([]);

  // Book Details
  useEffect(() => {
    const tempDetail = [];
    for (const val in productDetail.book) {
      const splitWords = val?.split(/(?=[A-Z])/);
      const name =
        splitWords !== undefined &&
        splitWords !== null &&
        splitWords.length !== 0
          ? splitWords
              .map(function (word) {
                return word.charAt(0).toUpperCase() + word.slice(1);
              })
              .join(' ')
          : val.charAt(0).toUpperCase() + val.slice(1);
      const value = productDetail.book[val];
      tempDetail.push({ name, value });
    }
    setTableContent(tempDetail);
  }, [productDetail?.book]);
  // Legend Template
  const legendTemplate = (title, icon) => {
    return (
      <div className='flex align-items-center'>
        <span className='mr-2'>
          <img src={icon} alt='' width='28px' />
        </span>
        <span>{title}</span>
      </div>
    );
  };
  // Audiences
  useEffect(() => {
    const tempDetail = [];
    for (const val in productDetail.audiences) {
      const splitWords = val?.split(/(?=[A-Z])/);
      const name =
        splitWords !== undefined &&
        splitWords !== null &&
        splitWords.length !== 0
          ? splitWords
              .map(function (word) {
                return word.charAt(0).toUpperCase() + word.slice(1);
              })
              .join(' ')
          : val.charAt(0).toUpperCase() + val.slice(1);
      const value =
        val === 'minReadingAge' || val === 'maxReadingAge'
          ? productDetail?.audiences[val]?.value
          : productDetail?.audiences[val];
      tempDetail.push({ name, value });
    }
    setTableContentAudience(tempDetail);
  }, [productDetail?.audiences]);
  // For Manufaturer Details Table
  useEffect(() => {
    const tempDetail = [];
    for (const val in productDetail.manufacturer) {
      const splitWords = val?.split(/(?=[A-Z])/);
      const name =
        splitWords !== undefined &&
        splitWords !== null &&
        splitWords.length !== 0
          ? splitWords
              .map(function (word) {
                return word.charAt(0).toUpperCase() + word.slice(1);
              })
              .join(' ')
          : val.charAt(0).toUpperCase() + val.slice(1);
      const value = productDetail.manufacturer[val];
      tempDetail.push({ name, value });
    }
    setTableContentManufacturer(tempDetail);
  }, [productDetail?.manufacturer]);

  // array toString
  const arrayFunc = (arr) => {
    return arr.length !== 0 && arr[0] !== ''
      ? arr.map((ele, index) => {
          return arr.length - 1 === index ? `${ele}.` : `${ele},`;
        })
      : 'NA';
  };
  return (
    <div className='grid'>
      <div className='md:col-6 sm:col-12 p-0'>
        <div className='md:col-12 col-12 mt-2 py-0'>
          <div className='text-left'>
            <Fieldset
              legend={legendTemplate('Book Details', book)}
              style={{ minHeight: '528px' }}
            >
              <div className='grid'>
                {tableContent.map((val) => {
                  return (
                    <div key={val.name} className='md:col-4 col-12'>
                      <StyledLabelBlack>{val.name}</StyledLabelBlack>
                      <div className='mt-1'>
                        {val?.name !== 'Publication Date'
                          ? val.value || 'NA'
                          : moment(val.value, 'YYYY-MM-DD').format(
                            'DD MMM YYYY'
                          ) || 'NA'}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Fieldset>
          </div>
        </div>
      </div>
      <div className='md:col-6 sm:col-12 p-0'>
        <div className='md:col-12 col-12 mt-2 py-0 pr-4 pl-0'>
          <div className='text-left'>
            <Fieldset legend={legendTemplate('Audience Details', audience)}>
              <div className='grid'>
                {tableContentAudience.map((val) => {
                  return (
                    <div key={val.name} className='md:col-6 col-12'>
                      <StyledLabelBlack>{val.name}</StyledLabelBlack>
                      <div className='mt-1'>
                        {val.name !== 'Subject Character'
                          ? val.value || 'NA'
                          : arrayFunc(val.value)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Fieldset>
          </div>
        </div>
      </div>
      <div className='md:col-6 col-12 p-0'>
        <div className='md:col-12 col-12 mt-2 py-0'>
          <div className='text-left'>
            <Fieldset
              legend={legendTemplate('Manufacturer Details', manufacture)}
            >
              <div className='grid'>
                {tableContentManufacturer.map((val) => {
                  return (
                    <div key={val.name} className='md:col-6 col-12'>
                      <StyledLabelBlack>{val.name}</StyledLabelBlack>
                      {(val.name === 'Manufacture Date' ||
                        val.name === 'Launch Date') && (
                          <div className='mt-1'>
                            {moment(val.value, 'YYYY-MM-DD').format(
                              'DD MMM YYYY'
                            ) || 'NA'}
                          </div>
                      )}
                      {val.name !== 'Manufacture Date' &&
                        val.name !== 'Launch Date' && (
                          <div className='mt-1'>
                            {val.name !== 'Included Components'
                              ? val.value || 'NA'
                              : arrayFunc(val.value)}
                          </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </Fieldset>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ItemDesc;
