import axios from 'axios';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useRef, useState } from 'react';

import excelimage from '../../assets/images/exceicon.png';

/** @type {*} */
const url =
  process.env.REACT_APP_APIIMPORTURL + process.env.REACT_APP_APIIMPORTURL_GET;
/**
 *
 *
 * @param {*} { dataType, marketPlace, request }
 * @return {*}
 */
const FileUploadComponent = ({
  dataType,
  marketPlace,
  request,
  setActiveTab
}) => {
  const [disableUpload, setDisableUpload] = useState(true);
  const [load, setLoad] = useState(true);
  const refresh = () => {
    return setLoad(!load);
  };
  /** @type {*} */
  const toast = useRef(null);
  /** @type {*} */
  const [totalSize, setTotalSize] = useState(0);
  /** @type {*} */
  const fileUploadRef = useRef(null);

  /**
   *
   *
   * @param {*} e
   */
  // To get total size of Uploaded files
  const onTemplateSelect = (e) => {
    setDisableUpload(false);
    /** @type {*} */
    let _totalSize = totalSize;
    /** @type {*} */
    const files = e.files;
    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0;
    });
    refresh();
    setTotalSize(_totalSize);
  };
  // File Validation
  useEffect(() => {
    const validate = fileUploadRef?.current?.getFiles();
    validate.forEach((file, index) => {
      const allowedExtensions =  ['.csv'] ; // ['.xlsx', '.xls', '.csv'];
      const fileExtension = file.name
        .substring(file.name.lastIndexOf('.'))
        .toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        toast.current.show({
          severity: 'error',
          summary: 'Invalid Format File',
          detail: 'Currently supported file formats are .xlsx, .xls, or .csv'
        });
        setDisableUpload(true);
      } else {
        const fileInMb = totalSize / (1024*1024); //  1000000;
        if (fileInMb > 12) {
          setDisableUpload(true);
          toast.current.show({
            severity: 'error',
            summary: 'File Size Exceeded',
            detail: 'Maximum allowed  file size is 12 MB'
          });
        }
      }
    });
  }, [load, totalSize]);

  /**
   *
   *
   * @param {*} e
   */
  // For Uploading files
  const onTemplateUpload = async (e) => {
    /** @type {*} */
    let _totalSize = 0;

    e.files.forEach((file) => {
      _totalSize += file.size || 0;
    });

    setTotalSize(_totalSize);

    /** @type {*} */
    const formData = new FormData();
    /** @type {*} */
    const files = e.files;
    // Add file to formData
    const fileName = 'file';
    for (let i = 0; i < files.length; i++) {
      formData.append(fileName, files[i]);
    }

    // Add request object to formData
    /** @type {*} */
    const requestObject = {
      requestType: request,
      marketplace: marketPlace,
      fieldgroup: dataType
    };

    /** @type {*} */
    const requestJson = JSON.stringify(requestObject);
    formData.append(
      'request',
      new Blob([requestJson], { type: 'application/json' })
    );

    try {
      setDisableUpload(true);
      toast.current.show({
        severity: 'info',
        summary: '',
        detail: 'The file is being uploaded'
      });
      await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: '*/*'
        }
      });
      fileUploadRef.current.clear();
      setDisableUpload(false);

      setTimeout(() => {
        setActiveTab(1);
      }, 1200);
    } catch (error) {
      setDisableUpload(false);
      // Handle the error
      toast.current.show({
        severity: 'danger',
        summary: 'Not uploaded ',
        detail: ' Please check your network and try or server down'
      });
    }
  };

  /**
   *
   *
   * @param {*} file
   * @param {*} callback
   */
  // Individual File Removal
  const onTemplateRemove = (file, callback) => {
    setDisableUpload(false);
    setTotalSize(totalSize - file.size);
    callback();
    refresh();
  };
  /**
   *
   *
   */
  // Remove all Files by clicking clear icon
  const onTemplateClear = () => {
    setTotalSize(0);
  };

  /**
   *
   *
   * @param {*} options
   * @return {*}
   */
  // Template for header
  const headerTemplate = (options) => {
    /** @type {*} */
    const { className, chooseButton, uploadButton, cancelButton } = options;
    /** @type {*} */
    const value = totalSize / 1024; // 10000;
    /** @type {*} */
    const formatedValue =
      fileUploadRef?.current?.formatSize(totalSize) ?? '0 KB';

    return (
      <div
        className={className}
        style={{
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
          width: 'auto'
        }}
      >
        {chooseButton}
        {uploadButton}
        {cancelButton}
        <div className='flex align-items-center gap-3 ml-auto'>
          <span>{formatedValue} / 10 MB</span>
          <ProgressBar
            value={value}
            showValue={false}
            style={{ width: '10rem', height: '12px' }}
          />
        </div>
      </div>
    );
  };

  /**
   *
   *
   * @param {*} file
   * @param {*} props
   * @return {*}
   */
  // Template for file
  const itemTemplate = (file, props) => {
    const fileName = file?.name;
    return (
      <div className='flex align-items-center flex-wrap'>
        <div className='flex align-items-center' style={{ width: '40%' }}>
          <img
            alt={fileName}
            role='presentation'
            src={excelimage}
            width='auto'
          />
          <span title={fileName} className='flex flex-column text-left ml-3'>
            {fileName.substring(0, 23 - 3) + '...'}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          value={props.formatSize}
          severity='warning'
          className='px-3 py-2'
        />
        <Button
          type='button'
          icon='pi pi-times'
          className='p-button-outlined p-button-rounded p-button-danger ml-auto'
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  /**
   *
   *
   * @return {*}
   */
  // Empty Template
  const emptyTemplate = () => {
    return (
      <div className='flex align-items-center flex-column'>
        <i
          className='pi pi-image mt-3 p-5'
          style={{
            fontSize: '5em',
            borderRadius: '50%',
            backgroundColor: 'var(--surface-b)',
            color: 'var(--surface-d)'
          }}
        />
        <span
          style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }}
          className='my-5'
        >
          Drag and Drop File Here
        </span>
      </div>
    );
  };

  /** @type {*} */
  // Options for choose
  const chooseOptions = {
    icon: 'pi pi-fw pi-images',
    iconOnly: true,
    className: 'custom-choose-btn p-button-rounded p-button-outlined'
  };

  /** @type {*} */
  // Options for upload
  const uploadOptions = {
    icon: 'pi pi-fw pi-cloud-upload',
    iconOnly: true,
    className: `custom-upload-btn p-button-success p-button-rounded p-button-outlined ${
      disableUpload && 'p-disabled'
    } `
  };

  /** @type {*} */
  // Options for cancel
  const cancelOptions = {
    icon: 'pi pi-fw pi-times',
    iconOnly: true,
    className:
      'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'
  };

  return (
    <div>
      {/* Alert Message Ref */}
      <Toast ref={toast} position='top-center' />
      {/* Tooltip for upload header icon */}
      <Tooltip target='.custom-choose-btn' content='Choose' position='bottom' />
      <Tooltip target='.custom-upload-btn' content='Upload' position='bottom' />
      <Tooltip target='.custom-cancel-btn' content='Clear' position='bottom' />
      {/* File Upload Component */}
      <FileUpload
        ref={fileUploadRef}
        name='file'
        multiple
        accept='.csv' // '.xlsx, .xls, .csv'
        customUpload
        uploadHandler={onTemplateUpload}
        onSelect={onTemplateSelect}
        onError={onTemplateClear}
        onClear={onTemplateClear}
        headerTemplate={headerTemplate}
        itemTemplate={itemTemplate}
        emptyTemplate={emptyTemplate}
        chooseOptions={chooseOptions}
        uploadOptions={uploadOptions}
        cancelOptions={cancelOptions}
      />
    </div>
  );
};

export default FileUploadComponent;
