// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wrap.pos-down {
  position: absolute;
  bottom: 5% Im !important;
  left: 50%;
  transform: translate(-50%, -50%);
}
.text {
  color: #bc3232;
  display: inline-block;
  margin-left: 5px;
}
/* Bounceball CSS */
.bounceball {
  display: inline-block;
  height: 37px;
  width: 15px;
}

.bounceball:before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #bc3232;
  transform-origin: 50%;
  animation: bounce 500ms alternate infinite ease;
}

@keyframes bounce {
  0% {
    top: 30px;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }

  35% {
    height: 15px;
    border-radius: 50%;
    transform: scaleX(1);
  }

  100% {
    top: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/generic/loading.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;AACA;EACE,kBAAkB;EAClB,wBAAwB;EACxB,SAAS;EACT,gCAAgC;AAClC;AACA;EACE,cAAc;EACd,qBAAqB;EACrB,gBAAgB;AAClB;AACA,mBAAmB;AACnB;EACE,qBAAqB;EACrB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,cAAc;EACd,MAAM;EACN,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,qBAAqB;EAErB,+CAA+C;AACjD;;AAqBA;EACE;IACE,SAAS;IACT,WAAW;IACX,kCAAkC;IAClC,oBAAoB;EACtB;;EAEA;IACE,YAAY;IACZ,kBAAkB;IAClB,oBAAoB;EACtB;;EAEA;IACE,MAAM;EACR;AACF","sourcesContent":[".wrap {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n.wrap.pos-down {\n  position: absolute;\n  bottom: 5% Im !important;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n.text {\n  color: #bc3232;\n  display: inline-block;\n  margin-left: 5px;\n}\n/* Bounceball CSS */\n.bounceball {\n  display: inline-block;\n  height: 37px;\n  width: 15px;\n}\n\n.bounceball:before {\n  position: absolute;\n  content: \"\";\n  display: block;\n  top: 0;\n  width: 15px;\n  height: 15px;\n  border-radius: 50%;\n  background-color: #bc3232;\n  transform-origin: 50%;\n  -webkit-animation: bounce 500ms alternate infinite ease;\n  animation: bounce 500ms alternate infinite ease;\n}\n\n@-webkit-keyframes bounce {\n  0% {\n    top: 30px;\n    height: 5px;\n    border-radius: 60px 60px 20px 20px;\n    transform: scaleX(2);\n  }\n\n  35% {\n    height: 15px;\n    border-radius: 50%;\n    transform: scaleX(1);\n  }\n\n  100% {\n    top: 0;\n  }\n}\n\n@keyframes bounce {\n  0% {\n    top: 30px;\n    height: 5px;\n    border-radius: 60px 60px 20px 20px;\n    transform: scaleX(2);\n  }\n\n  35% {\n    height: 15px;\n    border-radius: 50%;\n    transform: scaleX(1);\n  }\n\n  100% {\n    top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
