const Currency = (inputString) => {
  const currencySymbolMap = {
    USD: '$',
    EUR: '€',
    GBP: '£',
    JPY: '¥',
    AUD: 'A$',
    CAD: 'C$',
    CHF: 'Fr',
    CNY: '¥',
    SEK: 'kr',
    NZD: 'NZ$',
    INR: '₹',
    SGD: 'S$',
    HKD: 'HK$',
    ZAR: 'R',
    BRL: 'R$',
    NOK: 'kr',
    TRY: '₺',
    MXN: 'Mex$',
    RUB: '₽',
    IDR: 'Rp',
    KRW: '₩',
    AED: 'د.إ',
    PHP: '₱',
    MYR: 'RM',
    THB: '฿',
    PLN: 'zł',
    ISK: 'kr',
    DKK: 'kr',
    HUF: 'Ft',
    CZK: 'Kč'
  };
  // To convert string to symbol
  function replaceCurrencyCodesWithSymbols (inputString1) {
    const currencyCodeRegex = /\b[A-Z]{3}\b/g; // Matches 3-letter currency codes
    return inputString1?.replace(
      currencyCodeRegex,
      (match) => currencySymbolMap[match] || match
    );
  }
  return inputString && replaceCurrencyCodesWithSymbols(inputString);
};
export default Currency;
