import { filter } from 'lodash';
import moment from 'moment';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressBar } from 'primereact/progressbar';
import { useCallback, useEffect, useState } from 'react';

import Loading from '../../components/generic/loading';
import Pagination from '../books/Pagination';

/**
 *
 *
 * @return {*}
 */
const UploadedStageNew = ({ load }) => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [lastPage, setLastPage] = useState(1);
  const sizeList = [
    { name: 20, value: 20 },
    { name: 30, value: 30 },
    { name: 40, value: 40 }
  ];
  const [costData, setCostData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchCostData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetch(
        process.env.REACT_APP_APIIMPORTURL +
          `/v1/requests/import?page=${page - 1}&size=${size}`,
        {
          headers: {
            Accept: 'application/json;charset=UTF-8'
          }
        }
      );
      const data = await response.json();
      setLastPage(data?.page?.totalPages);
      setLoading(false);
      const updatedData = data._embedded.responseVOList;

      /** @type {*} */
      const filteredData = filter(
        updatedData,
        (item) => item.processingStatus === 'NEW'
      );
      setCostData(filteredData);
    } catch (error) {
      console.error('Error fetching cost data:', error);
    }
  }, [page, size]);

  useEffect(() => {
    fetchCostData();
  }, [fetchCostData, load]);

  /**
   *
   *
   * @param {*} datetime
   * @return {*}
   */

  /** @type {*} */
  // File Name Body
  const fileNameBody = (rowData) => {
    return <span>{rowData?.requestFile?.originalFileName}</span>;
  };
  // Request Body
  const requestBody = (rowData) => {
    return <span>{rowData.requestType}</span>;
  };
  // SubmittedBy Body
  const submittedByBody = (rowData) => {
    return <span>{rowData.submittedBy}</span>;
  };
  // Submitted Time Body
  const submittedTimeBody = (rowData) => {
    const originalTime = moment(rowData.submitTime).format(
      'DD MMM YYYY HH:mm:ss'
    );
    return <span>{originalTime}</span>;
  };

  // Validation body
  const validationBody = () => {
    return <ProgressBar mode='indeterminate' />;
  };
  const columns = [
    {
      field: 'requestFile.originalFileName',
      header: 'File Name',
      expander: true,
      body: fileNameBody
    },
    {
      field: 'requestType',
      header: 'Request Type',
      body: requestBody
    },
    {
      field: 'submittedBy',
      header: 'Submitted By',
      body: submittedByBody
    },
    {
      field: 'submitTime',
      header: 'Submitted Time',
      body: submittedTimeBody
    },
    {
      field: 'stats.validationStatus',
      header: 'Validation Status',
      body: validationBody
    }
  ];

  return (
    <div>
      {!loading && (
        <>
          <DataTable
            value={costData}
            dataKey='id'
            rows={15}
            paginatorTemplate='CurrentPageReport
    FirstPageLink
    PrevPageLink
    PageLinks
    NextPageLink
    LastPageLink
    RowsPerPageDropdown'
            currentPageReportTemplate='Showing {first} to {last} of {totalRecords} entries'
            rowsPerPageOptions={[15, 20, 30]}
          >
            {columns.map((col) => (
              <Column
                key={col.field}
                field={col.field}
                header={col.header}
                body={col.body}
                sortable
              />
            ))}
          </DataTable>
          <Pagination
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            lastPage={lastPage}
            sizeList={sizeList}
          />
        </>
      )}
      {loading && <Loading />}
    </div>
  );
};

export default UploadedStageNew;
