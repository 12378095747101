import { filter } from 'lodash';
import { Badge } from 'primereact/badge';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useCallback, useEffect, useState } from 'react';

import Loading from '../../components/generic/loading';
import Pagination from '../books/Pagination';

/**
 *
 *
 * @return {*}
 */
const UploadedStageFormatValidation = ({ load }) => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [lastPage, setLastPage] = useState(1);
  const sizeList = [
    { name: 20, value: 20 },
    { name: 30, value: 30 },
    { name: 40, value: 40 }
  ];
  /** @type {*} */
  const [selectedRow, setSelectedRow] = useState(null);
  /** @type {*} */
  const [costData, setCostData] = useState([]);
  /** @type {*} */
  const [loading, setLoading] = useState(false);
  /** @type {*} */
  const fetchCostData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetch(
        process.env.REACT_APP_APIIMPORTURL +
          `/v1/requests/import?page=${page - 1}&size=${size}`,
        {
          headers: {
            Accept: 'application/json;charset=UTF-8'
          }
        }
      );
      const data = await response.json();
      setLastPage(data?.page?.totalPages);
      setLoading(false);
      const updatedData = data._embedded.responseVOList.map((item) => {
        return item;
      });
      const filteredData = filter(
        updatedData,
        (item) => item.processingStatus === 'ERROR'
      );

      setCostData(filteredData);
    } catch (error) {
      console.error('Error fetching cost data:', error);
    }
  }, [page, size]);

  useEffect(() => {
    fetchCostData();
  }, [fetchCostData, load]);
  // File Name Body
  const fileNameBody = (rowData) => {
    return <span>{rowData?.requestFile?.originalFileName}</span>;
  };
  // Total records body
  const totalRecordsBody = (rowData) => {
    return <Badge value={rowData?.stats?.total || 0} severity='info' />;
  };
  // Error Body
  const errorBody = (rowData) => {
    return (
      <div>
        <i className='pi pi-exclamation-triangle text-orange-500 font-medium pr-1' />
        <span>{rowData?.stats?.errormsg}</span>
      </div>
    );
  };

  /** @type {*} */
  const columns = [
    {
      field: 'requestFile.originalFileName',
      header: 'File Name',
      expander: true,
      body: fileNameBody
    },
    {
      field: 'stats.total',
      header: 'Total Records',
      body: totalRecordsBody
    },

    {
      field: 'stats.errormsg',
      header: 'Error',
      body: errorBody
    }
  ];

  return (
    <div>
      {!loading && (
        <>
          <DataTable
            value={costData}
            dataKey='id'
            rows={15}
            selectionMode='single'
            selection={selectedRow}
            onSelectionChange={(e) => setSelectedRow(e.value)}
            paginatorTemplate='CurrentPageReport
        FirstPageLink
        PrevPageLink
        PageLinks
        NextPageLink
        LastPageLink
        RowsPerPageDropdown'
            currentPageReportTemplate='Showing {first} to {last} of {totalRecords} Entries'
            rowsPerPageOptions={[15, 20, 30]}
          >
            {columns.map((col) => (
              <Column
                key={col.field}
                field={col.field}
                header={col.header}
                body={col.body}
                sortable
              />
            ))}
          </DataTable>
          <Pagination
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            lastPage={lastPage}
            sizeList={sizeList}
          />{' '}
        </>
      )}
      {loading && <Loading />}
    </div>
  );
};

export default UploadedStageFormatValidation;
