import moment from 'moment';
import { Badge } from 'primereact/badge';
import { Fieldset } from 'primereact/fieldset';
import { useEffect, useState } from 'react';

import reputation from '../../assets/png/reputation.png';
import { StyledLabelBlack } from '../../components/routing/Styled';

const Reputations = ({ productDetail }) => {
  const [repData, setRepData] = useState([]);

  useEffect(() => {
    // Fetch from Ranking API
    fetch(
      process.env.REACT_APP_APIPRODUCTURL +
        `/v1/reputations/ranking/source/AMZ/id/${productDetail?.primaryId?.id}/latest`,
      {
        headers: {
          Accept: 'application/json;charset=UTF-8'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setRepData(data);
      })
      .catch(() => {
        // Handle any errors here
        // console.error('Error fetching dataTypeOption:', error);
      });
  }, [productDetail?.primaryId?.id]);

  // Legend Template
  const legendTemplate = (title, icon) => {
    return (
      <div className='flex align-items-center'>
        <span className='mr-2'>
          <img src={icon} alt='' />
        </span>
        <span>{title}</span>
      </div>
    );
  };
  return (
    <div className='pr-3'>
      {' '}
      <Fieldset
        legend={legendTemplate('Reputations', reputation)}
        style={{ minHeight: '100px' }}
      >
        <div className='mb-1 pl-3'>
          <StyledLabelBlack>Last Updated: </StyledLabelBlack>
          <span>
            {moment(repData[0]?.asof, 'YYYY-MM-DD').format('DD MMM YYYY') !==
            'Invalid date'
              ? moment(repData[0]?.asof, 'YYYY-MM-DD').format('DD MMM YYYY')
              : 'NA'}
          </span>
        </div>
        {repData?.map((ele) => {
          return (
            <div className='grid mb-1 p-3' key={ele.rank}>
              <div className='md:col-3 col-12'>
                <StyledLabelBlack>Rank</StyledLabelBlack>
                <div className='mt-1'>
                  <Badge value={`#${ele?.rank}`} severity='info' />
                  {` in ${ele?.relatesToTitle}`}
                </div>
              </div>
              <div className='md:col-3 col-12'>
                <StyledLabelBlack> 1 Month Average</StyledLabelBlack>
                <div className='mt-1'>
                  <Badge value={`#${ele?.average1Month}`} severity='info' />
                  {` in ${ele?.relatesToTitle}`}
                </div>
              </div>
              <div className='md:col-3 col-12'>
                <StyledLabelBlack> 6 Months Average</StyledLabelBlack>
                <div className='mt-1'>
                  <Badge value={`#${ele?.average6Month}`} severity='info' />
                  {` in ${ele?.relatesToTitle}`}
                </div>
              </div>
              <div className='md:col-3 col-12'>
                <StyledLabelBlack>12 Months Average</StyledLabelBlack>
                <div className='mt-1'>
                  <Badge value={`#${ele?.average12Month}`} severity='info' />
                  {` in ${ele?.relatesToTitle}`}
                </div>
              </div>
            </div>
          );
        })}
      </Fieldset>
    </div>
  );
};
export default Reputations;
