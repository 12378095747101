import './books.css';

import { Fieldset } from 'primereact/fieldset';
import { Galleria } from 'primereact/galleria';
import { Image } from 'primereact/image';
import { Tooltip } from 'primereact/tooltip';
import { useEffect, useState } from 'react';

import Currency from '../../components/generic/currency';
import noImage from '../../assets/images/noImage.jpg';
import basic from '../../assets/png/basic.png';
import binding from '../../assets/png/binding.png';
import bundled from '../../assets/png/bundle.png';
import country from '../../assets/png/country.png';
import description from '../../assets/png/description.png';
import edition from '../../assets/png/edition.png';
import isbn from '../../assets/png/isbn.png';
import language from '../../assets/png/language.png';
import manufacturer from '../../assets/png/manufacture.png';
import media from '../../assets/png/media.png';
import publisher from '../../assets/png/publisher.png';
import shortDesc from '../../assets/png/shortdesc.png';
import subTitle from '../../assets/png/subTitles.png';
import tag from '../../assets/png/tag.png';
import trade from '../../assets/png/trade.png';
import { StyledLabelBlack } from '../../components/routing/Styled';

const numeral = require('numeral');
/**
 *
 *
 * @param {*} { productDetail }
 * @return {*}
 */
const BasicData = ({ productDetail }) => {
  const [images, setImages] = useState([]);
  const icon = [
    isbn,
    subTitle,
    country,
    language,
    edition,
    binding,
    manufacturer,
    publisher,
    trade,
    tag,
    media,
    bundled
  ];
  // Data for Galleria
  useEffect(() => {
    const tempImg = [];
    productDetail.images.forEach((det) => {
      const itemImageSrc = det?.normal?.url || det?.hires?.url;
      const thumbnailImageSrc = det?.thumbnail?.url;
      const alt = det?.hires?.url;
      const title = det?.normal?.fileName;
      tempImg.push({ itemImageSrc, thumbnailImageSrc, alt, title });
    });
    setImages(tempImg);
  }, [productDetail]);
  /** @type {} */
  // Content for BasicData
  const content = [
    { name: 'ISBN', value: `${productDetail?.primaryId?.id || 'NA'}` },
    { name: 'Sub Title', value: `${productDetail?.subTitle || 'NA'}` },
    {
      name: 'Country of Origin',
      value: `${productDetail?.countryOfOrigin || 'NA'}`
    },
    { name: 'Language', value: `${productDetail?.book?.language || 'NA'}` },
    { name: 'Edition', value: `${productDetail?.book?.edition || 'NA'}` },
    { name: 'Binding', value: `${productDetail?.book?.binding || 'NA'}` },
    {
      name: 'Manufacturer',
      value: `${productDetail?.manufacturer?.manufacturer || 'NA'}`
    },
    {
      name: 'Published By',
      value: `${productDetail?.book?.publishedBy || 'NA'}`
    },
    {
      name: 'Trade In Eligible',
      value: `${productDetail?.tradeInEligible || 'NA'}`
    },
    { name: 'Tag Line', value: `${productDetail?.tagline || 'NA'}` },

    { name: 'Media Type', value: `${productDetail?.mediaType || 'NA'}` },
    {
      name: 'Is Bundled',
      value: `${productDetail?.isBundled || 'NA'}`
    },    
    {
      name: 'List Price',
      value: productDetail?.pricing?.listPrice 
              ? `${Currency(productDetail?.pricing?.listPrice?.currency)}
                 ${numeral(productDetail?.pricing?.listPrice?.amount).format('0,0')}`
              : 'NA'
    }
  ];
  //       `${productDetail?.pricing?.listPrice?.currency || ''}  ${productDetail?.pricing?.listPrice?.amount || 'NA'}`
  const responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '960px',
      numVisible: 4
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];
  // Template for Main Image
  const itemTemplate = (item) => {
    return (
      <>
        <Image
          src={item.itemImageSrc}
          alt={item.alt}
          width='315px'
          height='315px'
          className='productImage mb-2'
          preview
        />
      </>
    );
  };
  // Template for Thumbnail Image
  const thumbnailTemplate = (item) => {
    return (
      <>
        <img
          src={item.thumbnailImageSrc}
          alt={item.alt}
          style={{ display: 'block' }}
          width='75px'
          height='55px'
        />
      </>
    );
  };
  // Legend Template
  const legendTemplate = (title, icon) => {
    return (
      <div className='flex align-items-center'>
        <span className='mr-2'>
          <img src={icon} alt='' />
        </span>
        <span>{title}</span>
      </div>
    );
  };

  return (
    <div className='grid mt-2'>
      <div className='md:col-4 lg:col-4 col-12'>
        {images.length > 1
          ? (
            <div className='card'>
              <Galleria
                value={images}
                responsiveOptions={responsiveOptions}
                numVisible={2}
                circular
                item={itemTemplate}
                thumbnail={thumbnailTemplate}
                showItemNavigatorsOnHover
                thumbnailsPosition='bottom'
              />
            </div>
            )
          : (
            <div className='text-center p-3 h-full '>
              <Image
                src={images.length === 0 ? noImage : images[0]?.itemImageSrc}
                alt=''
                width='315px'
                height='315px'
                className='productImage'
                preview
              />
            </div>
            )}
      </div>
      <div className='md:col-4 lg:col-4 col-12 px-0 '>
        <Fieldset
          legend={legendTemplate('Basic Details', basic)}
          style={{ minHeight: '443px' }}
        >
          <div className='p-1 grid '>
            {content.map((val, index) => {
              return (
                <div className='md:col-6 lg:col-6 col-12' key={val.name}>
                  <div className='flex'>
                    <img src={icon[index]} alt='' width='22px' />
                    <StyledLabelBlack className='mt-2 ml-1'>
                      {val.name}
                    </StyledLabelBlack>
                  </div>
                  {val.name !== 'Description'
                    ? (
                      <div className='mt-1'>{val.value}</div>
                      )
                    : (
                      <>
                        <Tooltip
                          style={{ width: '300px' }}
                          className='mt-1'
                          target={val?.value?.length > 100 && '.custom'}
                        >
                          {val.value}
                        </Tooltip>
                        <div className='custom'>
                          {val?.value !== 'NA'
                            ? val.value.substring(0, 100 - 3) + '...'
                            : 'NA'}
                        </div>
                      </>
                      )}
                </div>
              );
            })}
          </div>
        </Fieldset>
      </div>
      <div className='md:col-4 lg:col-4 col-12 '>
        <Fieldset
          legend={legendTemplate('Short Description', shortDesc)}
          className='mb-2 mr-3'
          style={{ minHeight: '100px', overflowY: 'auto', height: '100px' }}
        >
          <p className='m-0'>{productDetail?.shortDescription || 'NA'}</p>
        </Fieldset>
        <Fieldset
          legend={legendTemplate('Description', description)}
          className='mr-3'
          style={{ minHeight: '336px', overflowY: 'auto', height: '336px' }}
        >
          <p className='m-0 '>{productDetail?.description || 'NA'}</p>
        </Fieldset>
      </div>
    </div>
  );
};
export default BasicData;
