import { saveAs } from 'file-saver'; // Import saveAs from file-saver
import * as XLSX from 'xlsx';
/**
 *
 *
 * @param {*} drilldownData
 * @param {*} saveAsFilename
 * @param {*} formattedData
 */
export const exportData = (drilldownData, saveAsFilename, formattedData) => {
  /** @type {*} */
  const worksheet = XLSX.utils.json_to_sheet(formattedData);
  /** @type {*} */
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'DataSheet');

  // Generate a buffer and save the file using FileSaver

  /** @type {*} */
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

  /** @type {*} */
  const dataBlob =
      new Blob([excelBuffer],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  saveAs(dataBlob, saveAsFilename + '.xlsx');
};
