import { useNavigate } from 'react-router-dom';

/**
 * Dictionary with the app routes
 */
export const Routes = (param) => {
  const navigate = useNavigate();
  const pageIconWithName = [
    {
      label: 'Import',
      icon: 'pi pi-file-import',
      command: () => {
        // Handle Home menu item click
        navigate('/import');
      },
      title: 'Import'
    },
    {
      label: 'Books',
      icon: 'pi pi-book',
      command: () => {
        // Handle Home menu item click
        navigate('/books');
      },
      title: 'Books'
    },
    {
      label: 'Reports',
      icon: 'pi pi-file-export',
      command: () => {
        // Handle Home menu item click
        navigate('/report');
      },
      title: 'Report'
    }
  ];
  const sidebarPageIcon = [
    {
      icon: 'pi pi-file-import',
      command: () => {
        // Handle Home menu item click
        navigate('/import');
      },
      title: 'Fetch'
    },
    {
      icon: 'pi pi-book',
      command: () => {
        // Handle Home menu item click
        navigate('/books');
      },
      title: 'Books'
    },
    {
      icon: 'pi pi-file-export',
      command: () => {
        // Handle Home menu item click
        navigate('/report');
      },
      title: 'Reports'
    }
  ];

  /** @type {*} */
  const sidebarHome = [
    {
      label: 'Dashboard',
      icon: 'pi pi-th-large'
    }
  ];
  switch (param) {
    case 'sidebarHome':
      return sidebarHome;
    case 'sidebarPageIcon':
      return sidebarPageIcon;
    default:
      return pageIconWithName;
  }
};
