import { useState } from 'react';

import { ButtonRefresh } from '../../components/generic/button';
import OptionTab from '../../components/generic/optionTab';
import {
  StyledSwitchButton,
  TabGridContainer
} from '../../components/routing/Styled';
import Upload from './Upload';
import UploadedImport from './UploadedImport';
import UploadedStages from './UploadedStages';

/**
 *
 *
 * @return {*}
 */
const Import = () => {
  // for refresh
  const [load, setLoad] = useState(false);
  /** @type {*} */
  // Variable to hold activeTab Value
  const [activeTab, setActiveTab] = useState(0);
  /** @type {*} */
  const [checked, setChecked1] = useState(false);
  /** @type {*} */
  // label and icon for tabs in import screen
  const tabs = [
    {
      label: 'Submit Request',
      icon: 'pi pi-upload'
    },
    {
      label: 'Fetch Requests',
      icon: 'pi pi-file'
    }
  ];
  // Refresh Trigger
  const refresh = () => {
    return setLoad(!load);
  };
  return (
    <>
      <TabGridContainer className='grid'>
        <div
          className='p-0  sm:col-12 md:col-7 lg:col-8'
          style={{ marginTop: '6px' }}
        >
          <OptionTab
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            number='2'
          />
        </div>

        {activeTab === 1 && (
          <div
            className='
          flex sm:col-12
          md:col-5
          lg:col-4
          align-items-center
          lg:justify-content-end
          sm:justify-content-start'
            style={{ marginTop: '6px' }}
          >
            <ButtonRefresh
              icon='-refresh'
              tooltip='Refresh'
              method={() => {
                refresh();
              }}
              extraClass='refreshButton'
            />
            {/* Switch button for toggle screeen */}
            {/*
            <label className='mr-2'>All Imports</label>
            <StyledSwitchButton
              checked={checked}
              onChange={(e) => setChecked1(e.value)}
            />
            <label className='ml-2'>By Stages</label>
            */}
          </div>
        )}
      </TabGridContainer>

      {activeTab === 0 && <Upload setActiveTab={setActiveTab} />}
      {activeTab === 1 &&
        (checked
          ? (
            <UploadedStages load={load} />
            )
          : (
            <UploadedImport load={load} />
            ))}
    </>
  );
};
export default Import;
