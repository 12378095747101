import { Player } from '@lottiefiles/react-lottie-player';
/**
 *
 *
 * @param {*} props
 * @return {*}
 */
const AnimePlayer = (props) => {
  const { src, style, className } = props;

  return (
    <>
      <Player className={className} style={style} autoplay loop src={src} />
    </>
  );
};
export default AnimePlayer;
