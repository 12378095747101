import { useEffect, useState } from 'react';

import { SearchInput } from '../../components/generic/Styled';
import { CardBookFilter } from '../../components/routing/Styled';
import { StyledTreeSelect } from '../../containers/Styled';

/**
 *
 *
 * @param {*} {
 *   marketPlace,
 *   setMarketPlace,
 *   marketPlaceOption,
 *   rankBy,
 *   setRankBy,
 *   rankByOption,
 *   sellerBy,
 *   sellerByOption,
 *   setSellerBy,
 *   tier,
 *   setTier,
 *   tierOption,
 *   discountBy,
 *   setDiscountBy,
 *   discountByOption,
 *   setSearchFilter
 * }
 * @return {*}
 */
const TopFilter = ({
  setSearchFilter,
  searchFil,
  setSearchFil,
  searchKey,
  setSearchKey,
  Onsearch,
  searchFilter
  

}) => {
  const [filterContent, setFilterContent] = useState({});
  /** @type {*} */
  const [selectedColumn, setSelectedColumn] = useState({});
  /** @type {*} */

  /** @type {*} */

  /** @type {*} */
  const [multipleOptionFilter, setMultipleOptionFilter] = useState([]);
  /** @type {*} */

  // searchFlag
  useEffect(() => {
    searchKey === '' && setSelectedColumn();
    searchFil === true && setMultipleOptionFilter([]);
  }, [searchKey, searchFil]);
  // Get Label
  const getLabel = (group, index) => {
    if (group === 'TITLES') {
      return `${filterContent[group]?.content[index]?.title}`;
    } else if (group === 'PUBLISHER') {
      return `${filterContent[group]?.content[index]?.publisher}`;
    } else if (group === 'AUTHORS') {
      return `${filterContent[group]?.content[index]?.contributor}`;
    } else if (group === 'IDENTITIES') {
      return `${filterContent[group]?.content[index]?.identifier?.id}`;
    }
  };
  // new
  useEffect(() => {
    const multiOption = [];
    let i = 0;
    for (const group in filterContent) {
      const groupIndex = i;
      const key = `${i}`;
      const label = `${group}`;
      const data = filterContent[group]?.content;
      const children = [];
      filterContent[group]?.content?.forEach((ele, index) => {
        const key = `${groupIndex}-${index}`;
        const label = getLabel(group, index);
        const data = filterContent[group]?.content[index];
        children.push({ key, label, data });
      });

      data?.content?.length !== 0 &&
        children.length !== 0 &&
        multiOption.push({ key, label, data, children });
      i = i + 1;
    }
    setMultipleOptionFilter(multiOption);
  }, [filterContent]); // eslint-disable-line
  useEffect(() => {
    fetch(
      process.env.REACT_APP_APIPRODUCTURL +
        `/v1/products/search/autocomplete?q=${searchKey}`,
      {
        headers: {
          Accept: 'application/json;charset=UTF-8'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setFilterContent(data);
      })
      .catch(() => {
        // Handle any errors here
        // console.error('Error fetching dataTypeOption:', error);
      });
  }, [searchKey]);
  // tree select OnSelect function
  /**
   *
   *
   * @param {*} e
   */

  const TreeSelectHandler = (e) => {
    const val = ['0', '1', '2', '3'];
    !val.includes(e.value) && setSelectedColumn(e.value);
    const finalArr = [];

    Object.keys(e.value).forEach((key) => {
      multipleOptionFilter.find((node) => {
        return node.children.forEach((ele) => {
          ele.key === e.value &&
            !finalArr.includes(ele?.data) &&
            finalArr.push(ele.data);
        });
      });
    });
    !val.includes(e.value) && setSearchFilter(finalArr);
  };

  return (
    <CardBookFilter className='mt-2 '>
      <div className='flex justify-content-center align-items-center'>
        { <SearchInput
          value={searchKey}
          onChange={(e) => {
            e.target.value !== '' ? setSearchFil(true) : setSearchFil(false);
            setSearchKey(e.target.value);
          }}
           
            options={searchFil ? multipleOptionFilter : []}
            metaKeySelection
          placeholder='Search By Title, Author, Publisher or ISBN'
          style={{
            height: '50px',
            borderRadius: '20px 0px 0px 20px',
            width: '255px'
          }}
        /> }
          {' '}
        
        { <span>
          <StyledTreeSelect
            value={selectedColumn}
            onChange={(e) => TreeSelectHandler(e)}
            options={searchFil ? multipleOptionFilter : []}
            metaKeySelection
            className='md:min-w-15px lg:min-w-20px'
            display='chip'
            placeholder='Results Based on Search'
            selectionMode='single'
            style={{
              borderRight: 'white',
              borderRadius: '0px 20px 20px 0px',
              width: '40px'
            }}
          />
        </span> }
       
      </div>
    </CardBookFilter>
  );
};
export default TopFilter;
