import Keycloak from 'keycloak-js';

/** @type {*} */
const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAKURL,
  realm: process.env.REACT_APP_KEYCLOAKREALM,
  clientId: process.env.REACT_APP_KEYCLOAKCLIENTID
  //  onLoad: 'login-required'

});

export default keycloak;
