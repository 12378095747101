import { DataTable } from 'primereact/datatable';
import { TreeSelect } from 'primereact/treeselect';
import styled from 'styled-components';

export const StyledDataTable = styled(DataTable)`
    .p-datatable-header {
        padding-top: 0 !important;
    }
    .p-datatable-tbody > tr > td .p-row-toggler {
        width: 1.5rem;
        height: 1.5rem;
    }
    .p-column-title, .p-datatable-tbody td, .p-paginator-current {
        font-size: ${props => props.theme.font.sizes.xs};
    }
    .p-row-toggler-icon {
        font-size: ${props => props.theme.font.sizes.xxs};
    }
    .p-paginator {
        padding: 0;
        .p-dropdown {
            height: 2rem;
            .p-dropdown-label {
                padding: 6px;
            }
        }
    }
`;
export const StyledTreeSelect = styled(TreeSelect)`
.p-treeselect-label.p-placeholder {
    line-height: 2.5;
}
`;
export const StageLabel = styled.span`

    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;

&.status-NEW,&.status-new{
    background-color: #B3E5FC;
    color: #23547B;
}

&.status-PROCESSING,&.status-processing,&.status-SCHEDULED,&.status-scheduled{
    background-color: #b3b5fc;
    color: #23427b;
}

&.status-DONE,&.status-done,&.status-PARTIAL_DONE,&.status-partial_done{
    background-color: #94dc6a;
    color: #238118;
}
&.status-validated,&.status-VALIDATED{
    background-color: #31c3b3;
    color: #094740;
}
&.status-error,&.status-ERROR{
    background-color: #ff716b;
    color: #992520;
}

.status-qualified {
    background-color: #C8E6C9;
    color: #256029;
}

.status-unqualified {
    background-color: #FFCDD2;
    color: #C63737;
}

.status-negotiation {
    background-color: #FEEDAF;
    color: #8A5340;
}


.status-renewal {
    background-color: #ECCFFF;
    color: #694382;
}

.status-proposal {
    background-color: #FFD8B2;
    color: #805B36;
}
`;

export const ExpirySpan = styled.span`
    color: black;
    padding: 5px;
    border-radius: 5px;
    background-color: #23547b33;
    color: #23547B;
    &.file-expires14,.file-expires13,.file-expires12,.file-expires11,.file-expires10{
        background-color: #3b82f647;
        color: #3B82F6;
    }
    &.file-expires9,.file-expires8,.file-expires7,.file-expires6,.file-expires5{
        background-color: #f67e3b40;
    color: #F67E3B;
    }
    &.file-expires4,.file-expires3{
        background-color: #ffba093b;
    color: #FFBA09;
    }
    &.file-expires2,.file-expires1{
        background-color: #ff00002e;
    color: #ff0000;
    }
}
`;
