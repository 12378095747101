import { Badge } from 'primereact/badge';
import { Fieldset } from 'primereact/fieldset';
import { useEffect, useState } from 'react';

import id from '../../assets/png/id.png';
import offer from '../../assets/png/offer.png';
import Currency from '../../components/generic/currency';
import { StyledLabelBlack } from '../../components/routing/Styled';

const PricingDiscount = ({ productDetail }) => {
  const numeral = require('numeral');
  const [listOffer, setListOffer] = useState({}); //eslint-disable-line
  useEffect(() => {
    fetch(
      process.env.REACT_APP_APIPRODUCTURL +
        `/v1/listings/source/AMZ/product/${productDetail?.primaryId?.id}?
page=0&size=20&sortby=status&sortOrder=ASC&status=ACTIVE`,
      {
        headers: {
          Accept: 'application/json;charset=UTF-8'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setListOffer(
          data?._embedded?.listingVOList[0]?.offersByType?.OFFERS[0]?.prices[0]
        );
      })
      .catch(() => {
        // Handle any errors here
        // console.error('Error fetching dataTypeOption:', error);
      });
  }, [productDetail?.primaryId?.id]);
  const content = [
    { name: 'Market ID', value: `${productDetail?.market?.id || 'NA'}` },
    {
      name: 'Market Source',
      value: `${productDetail?.market?.source || 'NA'}`
    },
    { name: 'Product Id', value: `${productDetail?.product?.id || 'NA'}` },
    {
      name: 'Product Source',
      value: `${productDetail?.product?.source || 'NA'}`
    },
    {
      name: 'Seller Id',
      value: `${productDetail?.seller?.id || 'NA'}`
    },
    {
      name: 'Seller Source',
      value: `${productDetail?.seller?.source || 'NA'}`
    }
  ];
  const contentMine = [
    {
      name: 'Landed Price',
      value: `${
        listOffer?.price?.buyingPrice?.landedPrice?.amount
          ? `${Currency(
              listOffer?.price?.buyingPrice?.landedPrice?.currency
            )} ${numeral(
              listOffer?.price?.buyingPrice?.landedPrice?.amount
            ).format('0,0')}`
          : 'NA'
      }`
    },
    {
      name: 'List Price',
      value: `${
        listOffer?.price?.buyingPrice?.listPrice?.amount
          ? `${Currency(
              listOffer?.price?.buyingPrice?.listPrice?.currency
            )} ${numeral(
              listOffer?.price?.buyingPrice?.listPrice?.amount
            ).format('0,0')}`
          : 'NA'
      }`
    },
    {
      name: 'Shipping Price',
      value: `${
        listOffer?.price?.buyingPrice?.shippingPrice?.amount
          ? `${Currency(
              listOffer?.price?.buyingPrice?.shippingPrice?.currency
            )} ${numeral(
              listOffer?.price?.buyingPrice?.shippingPrice?.amount
            ).format('0,0')}`
          : 'NA'
      }`
    },
    {
      name: 'Regular Price',
      value: `${
        listOffer?.price?.buyingPrice?.regularPrice?.amount
          ? `${Currency(
              listOffer?.price?.buyingPrice?.regularPrice?.currency
            )} ${numeral(
              listOffer?.price?.buyingPrice?.regularPrice?.amount
            ).format('0,0')}`
          : 'NA'
      }`
    },
    {
      name: 'Business Price',
      value: `${
        listOffer?.price?.buyingPrice?.businessPrice?.amount
          ? `${Currency(
              listOffer?.price?.buyingPrice?.businessPrice?.currency
            )} ${numeral(
              listOffer?.price?.buyingPrice?.businessPrice?.amount
            ).format('0,0')}`
          : 'NA'
      }`
    },
    {
      name: 'Manufacturer Price',
      value: `${
        listOffer?.price?.buyingPrice?.manufacturerPrice?.amount
          ? `${Currency(
              listOffer?.price?.buyingPrice?.manufacturerPrice?.currency
            )} ${numeral(
              listOffer?.price?.buyingPrice?.manufacturerPrice?.amount
            ).format('0,0')}`
          : 'NA'
      }`
    },
    {
      name: 'Suggested Price',
      value: `${
        listOffer?.price?.buyingPrice?.suggestedPrice?.amount
          ? `${Currency(
              listOffer?.price?.buyingPrice?.suggestedPrice?.currency
            )} ${numeral(
              listOffer?.price?.buyingPrice?.suggestedPrice?.amount
            ).format('0,0')}`
          : 'NA'
      }`
    },
    {
      name: 'Pricing Model',
      value: `${listOffer?.price?.pricingModel || 'NA'}`
    },
    {
      name: 'IsBuyboxPrice',
      value: `${listOffer?.price?.isBuyboxPrice || 'NA'}`
    },
    {
      name: 'IsTradeInPrice',
      value: `${listOffer?.price?.isTradeInPrice || 'NA'}`
    }
  ];
  const legendTemplate = (title, icon) => {
    return (
      <div className='flex align-items-center'>
        <span className='mr-2'>
          <img src={icon} alt='' />
        </span>
        <span>{title}</span>
      </div>
    );
  };
  return (
    <>
      <div className='grid'>
        <div className=' md:col-6 sm:col-12 pl-2 py-0'>
          <Fieldset
            legend={legendTemplate('Source & ID', id)}
            style={{ minHeight: '240px' }}
          >
            <div className='grid'>
              {' '}
              <div className='md:col-12 col-12'>
                <div className='p-3 grid'>
                  {content.map((val) => {
                    return (
                      <div className='md:col-4 col-12' key={val.name}>
                        <StyledLabelBlack>{val.name}</StyledLabelBlack>
                        <div className='mt-1'>{val.value}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Fieldset>
        </div>
        <div className=' md:col-6 sm:col-12  p-0 pr-4'>
          <div className='text-left'>
            <Fieldset
              legend={legendTemplate('Pricing & Offers', offer)}
              style={{ minHeight: '240px' }}
            >
              <div className='p-3 grid'>
                {contentMine.map((val) => {
                  return (
                    <div className='md:col-3 col-12' key={val.name}>
                      <StyledLabelBlack>{val.name}</StyledLabelBlack>
                      <div className='mt-1'>
                        {val?.value === 'NA'
                          ? (
                              val.value
                            )
                          : (
                            <Badge value={val.value} severity='success' />
                            )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Fieldset>
          </div>
        </div>
      </div>
    </>
  );
};
export default PricingDiscount;
