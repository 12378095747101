import './index.css';
import '../node_modules/primeicons/primeicons.css';
import '../node_modules/primeflex/primeflex.css';
import '../node_modules/primereact/resources/themes/md-light-indigo/theme.css';
import '../node_modules/primereact/resources/primereact.css';

import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';

export const Authorization = createContext();

/** @type {*} */
const auth = false;
/** @type {*} */
const verifyToken = null;
/** @type {*} */
const role = '';
/** @type {*} */
/** @type {*} */
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Authorization.Provider value={{ auth, verifyToken, role }}>
    <App />
  </Authorization.Provider>
);

reportWebVitals();
