import { useContext } from 'react';

import { subscriberContext } from '../../App';
import { FooterWrapper } from './Styled';

/**
 *
 *
 * @return {*}
 */
const Footer = () => {
  /** @type {*} */
  // Data from App.jsx
  const data = useContext(subscriberContext);
  return (
    <>
      <FooterWrapper
        style={{
          width: `${
            !data.sideBarFull ? 'calc(100% - 165px)' : 'calc(100% - 75px)'
          }`
        }}
        className='flex ml-auto'
      >
        <div className='w-full flex justify-content-end align-items-center'>
          <div style={{ fontSize: '12px' }}>© 2023 Bookswagon (R)</div>
        </div>
      </FooterWrapper>
    </>
  );
};

export default Footer;
