import moment from 'moment/moment';
import { FilterMatchMode } from 'primereact/api';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';

import noImage from '../../assets/images/noImage.jpg';
import Currency from '../../components/generic/currency';
import Loading from '../../components/generic/loading';
import { LoadingOverlay } from '../../components/generic/Styled';
import {
  StyledLabelBlack,
  StyleTreeSelect
} from '../../components/routing/Styled';
import BookTable from '../../containers/table/BookTable';
import TopFilter from '../books/TopFilter';
import Pagination from './Pagination';
import ProductDetails from './ProductDetails';

const numeral = require('numeral');

const BookMainFile = ({
  setAllowBookMain,
  setProductDetail,
  setTableData,
  tableData,
  allowBookMain,
  productDetail,
  searchKey,
  setSearchKey
}) => {
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [totalNumberBooks,setTotalNumberBooks] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [searchFil, setSearchFil] = useState(false);

  const [tableMainData, setTableMainData] = useState();
  const [filterTableMainData, setFilterTableMainData] = useState([]); //eslint-disable-line
  const [groupColumns, setgroupColumns] = useState([]);
  const [marketPlace, setMarketPlace] = useState(); //eslint-disable-line
  const [rankBy, setRankBy] = useState(); //eslint-disable-line
  const [sellerBy, setSellerBy] = useState(); //eslint-disable-line
  const [tier, setTier] = useState(); //eslint-disable-line
  const [discountBy, setDiscountBy] = useState(); //eslint-disable-line
  const [searchFilter, setSearchFilter] = useState([]); //eslint-disable-line

  const [selectedNodeKeys, setSelectedNodeKeys] = useState();
  const [multipleOption, setMultipleOption] = useState([]);
  const [visibleGroupColumn, setVisibleGroupColumn] = useState([]);
  const [filters, setFilters] = useState({});
  // Image Body For List table
  const imageBody = (rowData) => {
    return (
      <div
        onClick={() => {
          setAllowBookMain(false); // To show Product Detail
          setProductDetail(rowData);
        }}
        className='flex align-items-center gap-2'
        style={{ cursor: 'pointer' }}
      >
        <img
          src={
            rowData?.images[0]?.thumbnail?.url !== 'NA'
              ? rowData?.images[0]?.thumbnail?.url || noImage
              : noImage
          }
          alt=''
          height='75px'
          width='56px'
        />
      </div>
    );
  };
  // Body for Last Refreshed Date
  const lastRefreshed = (rowData) => {
    // Parsing the date string
    const dateObj = moment(rowData?.lastRefreshed?.metadata); // Moment object representing the date

    // Formatting the date in a specific format
    return dateObj.format('DD MMM YYYY HH:mm:ss');
  };
  // Body for listPrice Body
  const listPrice = (rowData) => {
    return rowData?.pricing?.listPrice
      ? `${Currency(rowData?.pricing?.listPrice?.currency)}${numeral(
          rowData?.pricing?.listPrice?.amount
        ).format('0,0')}`
      : 'NA';
  };
  // Body for mrp Body
  const mrpBody = (rowData) => {
    return rowData?.pricing?.mrp
      ? `${Currency(rowData?.pricing?.mrp?.currency)}${numeral(
          rowData?.pricing?.mrp?.amount
        ).format('0,0')}`
      : '';
  };
  // Body for release Body
  const releaseBody = (rowData) => {
    const dateObj = moment(rowData?.releaseDate); // Moment object representing the date

    // Formatting the date in a specific format
    return dateObj.format('DD MMM YYYY') !== 'Invalid date'
      ? dateObj.format('DD MMM YYYY')
      : 'NA';
  };
  // Body for Category
  const categoriesBody = (rowData) => {
    return rowData.categories.map((ele, index) => {
      return `${ele?.value}${
        rowData.contributors.length - 1 !== index ? ',' : '.'
      }`;
    });
  };
  // Body for Author
  const authorBody = (rowData) => {
    if (rowData?.contributors?.length !== 0) {
      return rowData?.contributors.map((ele, index) => {
        if (ele?.role === 'AUTHOR' && ele?.person) {
          if (ele.person.includes(',')) {
            return `${ele.person}.`;
          } else {
            return ele.person;
          }
        } else {
          return 'NA';
        }
      });
    } else {
      return 'No Author';
    }
  };

  // Title Body
  const titleBody = (rowData) => {
    if (rowData?.title.length > 25) {
      return (
        <div
          onClick={() => {
            setAllowBookMain(false); // To show Product Detail
            setProductDetail(rowData);
          }}
          className='hyperlink'
          title={rowData?.title}
        >
          {rowData?.title.substring(0, 25 - 3) + '...'}
        </div>
      );
    }
    return (
      <div
        onClick={() => {
          setAllowBookMain(false); // To show Product Detail
          setProductDetail(rowData);
        }}
        className='hyperlink'
        title={rowData?.title}
      >
        {rowData?.title}
      </div>
    );
  };
  // Published Body
  const publishedBody = (rowData) => {
    if (rowData?.book?.publishedBy?.length > 25) {
      return (
        <div title={rowData?.book?.publishedBy}>
          {rowData?.book?.publishedBy?.substring(0, 30 - 3) + '...'}
        </div>
      );
    } else if (
      rowData?.book?.publishedBy?.length < 25 &&
      rowData?.book?.publishedBy?.length > 0
    ) {
      return (
        <div title={rowData?.book?.publishedBy}>
          {rowData?.book?.publishedBy}
        </div>
      );
    } else {
      return 'NA';
    }
  };
  // Default Columns
  const defaultColumns = [
    {
      id: 'image',
      header: 'Image',
      body: imageBody
    },
    {
      id: 'primaryId',
      field: 'primaryId.id',
      header: 'ISBN'
    },
    {
      id: 'sourceId',
      field: 'sourceId.id',
      header: 'ASIN'
    },
    {
      id: 'title',
      field: 'title',
      header: 'Title',
      body: titleBody
    },
    {
      id: 'author',
      header: 'Author',
      body: authorBody
    },
    {
      id: 'lastRefreshed.metadata',
      header: 'Last Refreshed',
      field: 'lastRefreshed.metadata',
      body: lastRefreshed
    },
    {
      id: 'pricing.listPrice.amount',
      header: 'List Price',
      field: 'pricing.listPrice.amount',
      body: listPrice
    },
    {
      id: 'publishedBy',
      field: 'book.publishedBy',
      header: 'Published By',
      body: publishedBody
    }
  ];
  const sizeList = [
    { name: 20, value: 20 },
    { name: 30, value: 30 },
    { name: 40, value: 40 }
  ];
  useEffect(() => {
    fetch(
      process.env.REACT_APP_APIPRODUCTURL +
        `/v1/products?page=${
          page - 1
        }&size=${size}&sortby=status&sortOrder=ASC&status=ACTIVE`,
      {
        headers: {
          Accept: 'application/json;charset=UTF-8'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(true);
        // Handle the response data here
        setTableMainData(data?._embedded?.productVOList);
        setLastPage(data?.page?.totalPages);
        setTotalNumberBooks(data?.page?.totalElements);
        setLoading(false);
      })
      .catch(() => {
        // Handle any errors here
        // console.error('Error fetching dataTypeOption:', error);
      });
  }, [page, size]);

  // Manufacture Date format
  const manufactureDateBody = (rowData) => {
    const dateObj = moment(rowData?.manufacturer?.manufactureDate);
    return dateObj.format('DD MMM YYYY');
  };

  // Filters for Individual Column
  useEffect(() => {
    let finalObj = {};
    visibleGroupColumn.forEach((ele, index) => {
      const value = { value: null, matchMode: FilterMatchMode.CONTAINS };
      const key = ele.field;
      const obj = { [key]: value };
      finalObj = Object.assign(finalObj, obj);
    });
    visibleGroupColumn.length !== 0 && setFilters(finalObj);
  }, [visibleGroupColumn]);

  // Fetch GroupColumn
  useEffect(() => {
    setLoading(true);
    fetch(
      process.env.REACT_APP_APIIMPORTURL +
        '/v1/fieldgroups?page=0&size=50&sortby=status&sortOrder=ASC&status=ACTIVE',
      {
        headers: {
          Accept: 'application/json;charset=UTF-8'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data here
        const filteredFieldGroups = data?._embedded?.fieldGroupVOList.map(
          (ele, index) => {
            const filterFields = ele?.fields.filter((val, index1) => {
              return (
                val.jsonPath.substring(2) !== 'book.publishedBy' &&
                val.jsonPath.substring(2) !== 'title' &&
                val.jsonPath.substring(2) !== 'lastRefreshed.metadata' &&
                val.jsonPath.substring(2) !== 'pricing.listPrice' &&
                val.jsonPath.substring(2) !== 'sourceId'
              );
            });
            ele.fields = filterFields;
            return ele;
          }
        );

        setgroupColumns(filteredFieldGroups);
      })
      .catch(() => {
        // Handle any errors here
      });
  }, []);
  // For Group Data
  const dataGroup = (group) => {
    const parentData = [];
    if (group.fields) {
      group.fields.forEach((field, index) => {
        const id = `${index}-${field.code}`;
        const jsonPath = field?.jsonPath?.substring(2);
        const fieldPath =
          jsonPath === 'pricing.mrp' ? 'pricing.mrp.amount' : jsonPath;
        const header = field.name.charAt(0).toUpperCase() + field.name.slice(1);
        const getBody = () => {
          if (field.name === 'Categories') {
            return categoriesBody;
          } else if (field.name === 'Manufacture Date') {
            return manufactureDateBody;
          } else if (jsonPath === 'pricing.mrp') {
            return mrpBody;
          } else if (field.name === 'Release Date') {
            return releaseBody;
          }
        };
        const body = getBody();

        parentData.push({ id, field: fieldPath, header, body });
      });
    }

    const id = group.code;
    const header = group.name.charAt(0).toUpperCase() + group.name.slice(1);
    const field =
      group?.jsonPath?.substring(2) === 'pricing.mrp'
        ? 'pricing.mrp.amount'
        : group?.jsonPath?.substring(2);
    const body = group.code === 'images' && imageBody;

    !group.fields &&
      (body
        ? parentData.push({ id, field, header, body })
        : parentData.push({ id, field, header }));
    return parentData;
  };
  // To generate Group
  useEffect(() => {
    const generate = () => {
      const multiOption = [];
      groupColumns.forEach((group, index) => {
        const grp = dataGroup(group);
        const key = group.fields ? `${index}` : `${index}-${1}`;
        const label = `${
          group.name.charAt(0).toUpperCase() + group.name.slice(1)
        }`;
        const data = grp;
        const children = [];
        if (group.fields) {
          group.fields.forEach((field, indexChild) => {
            const key = `${index}-${indexChild}`;
            const label = `${
              field.name.charAt(0).toUpperCase() + field.name.slice(1)
            }`;
            const fieldData = grp[indexChild];
            children.push({ key, label, data: fieldData });
          });
        }

        multiOption.push({ key, label, data, children });
      });

      setMultipleOption(multiOption);
    };
    generate();
  }, [groupColumns]); //eslint-disable-line
  useEffect(() => {
    multipleOption.length !== 0 && setVisibleGroupColumn(defaultColumns);
  }, [multipleOption]); //eslint-disable-line

  // tree select OnSelect function
  const TreeSelectHandler = (e) => {
    setSelectedNodeKeys(e.value);
    const finalArr = [];
    Object.keys(e.value).forEach((key) => {
      multipleOption.find((node) => {
        return node.children.length !== 0
          ? node.children.forEach((ele) => {
              ele.key === key && finalArr.push(ele.data);
            })
          : node.key === key && finalArr.push(node.data);
      });
    });

    finalArr.unshift(defaultColumns);

    setVisibleGroupColumn(finalArr.flat());
  };

  // To Update Data based on Filter
  useEffect(() => {
    setFilterTableMainData([]);
    searchFilter[0]?.links[0]?.href &&
      searchFilter?.forEach((ele, index) => {
        fetch(searchFilter[index]?.links[0]?.href, {
          headers: {
            Accept: 'application/json;charset=UTF-8'
          }
        })
          .then((response) => response.json())
          .then((data) => {
            const temp = [];
            temp.push(data);
            setFilterTableMainData(temp);
          })
          .catch(() => {
            // Handle any errors here
          });
      });
  }, [searchFilter]);
  useEffect(() => {
    !searchFil && setFilterTableMainData([]);
  }, [searchFil]);
  return (
    <>
      <Toast ref={toast} />
      {/*
      {allowBookMain && (
        <TopFilter
          marketPlace={marketPlace}
          setMarketPlace={setMarketPlace}
          rankBy={rankBy}
          setRankBy={setRankBy}
          sellerBy={sellerBy}
          setSellerBy={setSellerBy}
          tier={tier}
          setTier={setTier}
          discountBy={discountBy}
          setDiscountBy={setDiscountBy}
          setSearchFilter={setSearchFilter}
          searchFil={searchFil}
          setSearchFil={setSearchFil}
          searchKey={searchKey}
          setSearchKey={setSearchKey}
        />
              <div
          className=' mb-2 md:flex sm:grid justify-content-between align-items-center'
          style={{ paddingTop: '5px' }}
        >)}      
      */}
      {allowBookMain && (
        <div
          className=' mb-2 md:flex sm:grid justify-content-between align-items-center'
          style={{ padding: '8px',marginTop:'5px',border: '1px solid #c6c3c3',borderRadius: '16px',
          backgroundColor: '#e5d9d9',height: 'auto'}}
        >
          <div>
            <i
              className='pi pi-bars mr-2 pt-2'
              style={{ fontSize: '1.5rem' }}
            />
            <StyledLabelBlack className='pt-2'>Books: {totalNumberBooks}</StyledLabelBlack>
 		  </div>
          <div>
            <TopFilter
            marketPlace={marketPlace}
            setMarketPlace={setMarketPlace}
            rankBy={rankBy}
            setRankBy={setRankBy}
            sellerBy={sellerBy}
            setSellerBy={setSellerBy}
            tier={tier}
            setTier={setTier}
            discountBy={discountBy}
            setDiscountBy={setDiscountBy}
            setSearchFilter={setSearchFilter}
            searchFil={searchFil}
            setSearchFil={setSearchFil}
            searchKey={searchKey}
            setSearchKey={setSearchKey}

            // data={
            //   filterTableMainData.length !== 0 && searchFil === true
            //     ? filterTableMainData
            //     : tableMainData
            // }
            filters={filters}
            setFilters={setFilters}
            setTableData={setTableData}
           
          />
          </div>
          <div>
            {' '}
            <div className='md:flex sm:grid'>
              <StyleTreeSelect
                value={selectedNodeKeys}
                onChange={(e) => TreeSelectHandler(e)}
                options={multipleOption}
                metaKeySelection
                className='lg:h-2rem md:h-2.5rem sm:h-2.5rem md:w-20rem w-full mr-6'
                selectionMode='checkbox'
                display='chip'
                placeholder='Select Columns'
                filter
              />
            </div>
          </div>
        </div>
      )}
      {allowBookMain && !loading && (
        <>
          <Pagination
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            setAllowBookMain={setAllowBookMain}
            lastPage={lastPage}
            sizeList={sizeList}
          />
          <hr style={{border: '1px solid #e4e4e4'}}/>
          {' '}
          <BookTable
            data={
              filterTableMainData.length !== 0 && searchFil === true
                ? filterTableMainData
                : tableMainData
            }
            columns={visibleGroupColumn}
            filters={filters}
            setFilters={setFilters}
            setTableData={setTableData}
          />
          <Pagination
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            setAllowBookMain={setAllowBookMain}
            lastPage={lastPage}
            sizeList={sizeList}
          />
        </>
      )}
      {!allowBookMain && (
        <ProductDetails
          setAllowBookMain={setAllowBookMain}
          productDetail={productDetail}
          setProductDetail={setProductDetail}
          tableData={tableData}
          setTableData={setTableData}         
          page={page}
          lastPage={lastPage}
          size={size}
          setPage={setPage}
          setLastPage={setLastPage}
        />
      )}
      {loading && (
        <LoadingOverlay>
          <Loading />
        </LoadingOverlay>
      )}
    </>
  );
};
export default BookMainFile;
