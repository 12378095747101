import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { subscriberContext } from '../../App';
import { Theme } from '../../containers/styled/Theme';
import Books from '../../screens/books/Books';
import Import from '../../screens/import/Import';
import Report from '../../screens/report/Report';
import Footer from './Footer';
import keycloak from './Keycloak';
import LeftSideBar from './LeftSideBar';
import {
  AppWrapper,
  CardLayout,
  Container,
  ContentArea,
  InnerContent
} from './Styled';

const PageInfo = lazy(() => import('./PageInfo'));

/**
 * Component that distribute the app layout:
 * - the left side navigation bar
 * - navbar top with subscriber info
 * - and the component linked to the route.
 *
 *     +------+-------------------------+
 *     | Logo |            Subscriber   |
 *     |      +-------------------------+
 *     |      |                         |
 *     |  N   |                         |
 *     |  a   |                         |
 *     |  v   |       Component         |
 *     |  b   |                         |
 *     |  a   |                         |
 *     |  r   |                         |
 *     |      |                         |
 *     +------+-------------------------+
 *
 * @component
 */

const AppLayout = () => {
  const data = useContext(subscriberContext);
  return (
    <AppWrapper>
      <Suspense>
        <Container>
          <LeftSideBar />
          <ContentArea>
            <PageInfo />
            <InnerContent
              style={{
                width: `${
                  data.sideBarFull
                    ? 'calc(100vw - 102px)'
                    : 'calc(100vw - 188px)'
                }`
              }}
            >
              <CardLayout>
                <Suspense>
                  <Outlet />
                </Suspense>
              </CardLayout>
            </InnerContent>
            <Footer />
          </ContentArea>
        </Container>
      </Suspense>
    </AppWrapper>
  );
};

const AppRoutes = () => {
  const [authenticated, setAuthenticated] = useState(false);
  // Keycloak Authetication
  useEffect(() => {
    keycloak
      .init({ onLoad: 'login-required' })
      .then((authenticated) => {
        setAuthenticated(authenticated);
        localStorage.setItem('token', keycloak.token);
        localStorage.setItem(
          'tokenParsed',
          JSON.stringify(keycloak.tokenParsed)
        );
        localStorage.setItem('refreshToken', keycloak.refreshToken);
        localStorage.setItem(
          'refreshTokenParsed',
          JSON.stringify(keycloak.refreshTokenParsed)
        );

        setInterval(() => {
          if (isTokenExpired()) {
            refreshToken()
              .then(() => {
                localStorage.setItem('token', keycloak.token);
                localStorage.setItem('refreshToken', keycloak.refreshToken);
              })
              .catch((error) => {
                console.error('Failed to refresh token:', error);
              });
          }
        }, 60000);
      })
      .catch((error) => {
        console.error('Keycloak initialization error:', error);
      });
  }, []);

  const isTokenExpired = () => {
    const expiresIn = keycloak.tokenParsed.exp;
    return Date.now() >= expiresIn * 1000;
  };

  const refreshToken = () => {
    return new Promise((resolve, reject) => {
      keycloak
        .updateToken(5)
        .then((refreshed) => {
          if (refreshed) {
            resolve();
          } else {
            reject(new Error('Token not refreshed'));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return (
    <BrowserRouter>
      <ThemeProvider theme={Theme}>
        <Routes>
          {/* Authentication  Based on variable authenticated */}
          {authenticated && (
            <Route path='/' element={<AppLayout />}>
              <Route path='import' element={<Import />} />
              <Route path='books'  element={<Books />} />
              <Route path='report' element={<Report />} />
            </Route>
          )}
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default AppRoutes;
