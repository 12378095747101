import { filter } from "lodash";
import moment from "moment";
import { FilterMatchMode } from "primereact/api";
import { Avatar } from "primereact/avatar";
import { useEffect, useState } from "react";

import { ButtonGeneric } from "../../components/generic/button";
import Loading from "../../components/generic/loading";
import { StyledLabelBlack } from "../../components/routing/Styled";
import { StageLabel } from "../../containers/Styled";
import ReportTable from "../../containers/table/ReportTable";
import Pagination from "../books/Pagination";

/**
 *
 *
 * @return {*}
 */
const Report = () => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [lastPage, setLastPage] = useState(1);
  // Report Table Data Variable
  const [dataMain, setDataMain] = useState([]);
  // Loading Screen variable
  const [loading, setLoading] = useState(false);
  // fetching Report Data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          process.env.REACT_APP_APIIMPORTURL +
            `/v1/requests/report?page=${page - 1}&size=${size}`,
          {
            headers: {
              Accept: "application/json;charset=UTF-8",
            },
          }
        );
        const data = await response.json();
        setLastPage(data?.page?.totalPages);
        setLoading(false);
        const updatedData = data._embedded.responseVOList;
        const filteredData = filter(
          updatedData,
          (item) => calculate(item.submitTime) >= 0
        );

        setDataMain(filteredData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [page, size]);
  const sizeList = [
    { name: 20, value: 20 },
    { name: 30, value: 30 },
    { name: 40, value: 40 },
  ];
  /** @type {*} */
  // Assigning Filters for all individual column manually
  const [filters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "requestFile.originalFileName": {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    submittedBy: { value: null, matchMode: FilterMatchMode.CONTAINS },
    submitTime: { value: null, matchMode: FilterMatchMode.CONTAINS },
    processingStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
    reportType: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  /**
   *
   *
   * @param {*} rowData
   */
  // Download Excel Request File
  const downloadExcel = async (rowData) => {
    const url = rowData.requestFile?._links?.download?.href;

    // Replace 'file_url' with the actual URL of the file you want to download.
    /** @type {*} */
    const fileUrl = url;

    // Create a temporary anchor element.
    /** @type {*} */
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    // Extract the filename from the fileUrl and set it as the download attribute.
    /** @type {*} */
    const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
    anchor.setAttribute("download", fileName);

    // Append the anchor element to the body.
    document.body.appendChild(anchor);

    // Programmatically click the anchor element to trigger the download.
    anchor.click();

    // Remove the anchor element from the body.
    document.body.removeChild(anchor);
  };

  /**
   *
   *
   * @param {*} rowData
   */
  // Download Response Excel
  const downloadResponseExcel = (rowData) => {
    const url = rowData?.reportFile?._links?.download?.href;
    // Replace 'file_url' with the actual URL of the file you want to download.
    /** @type {*} */
    const fileUrl = url;

    // Create a temporary anchor element.
    /** @type {*} */
    const anchor = document.createElement("a");
    anchor.href = fileUrl;

    // Extract the filename from the fileUrl and set it as the download attribute.
    /** @type {*} */
    const fileName = fileUrl?.substring(fileUrl.lastIndexOf("/") + 1);
    anchor.setAttribute("download", fileName);

    // Append the anchor element to the body.
    document.body.appendChild(anchor);

    // Programmatically click the anchor element to trigger the download.
    anchor.click();

    // Remove the anchor element from the body.
    document.body.removeChild(anchor);
  };

  /**
   *
   *
   * @param {*} rowData
   * @return {*}
   */
  // Body For SubmittedBy
  const imageWithText = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <Avatar icon="pi pi-user" size="medium" shape="circle" />
        <span>{rowData.submittedBy}</span>
      </div>
    );
  };

  /**
   *
   *
   * @param {*} rowData
   * @return {*}
   */
  // Action body for download by clicking download icon
  const actionBody = (rowData) => {
    return (
      <>
        <ButtonGeneric
          toolpos="left"
          tooltip="Download Request File"
          extraClass="downloadGenericButton bg-primary-400"
          icon="pi pi-download"
          disabled={!rowData.requestFile}
          method={() => {
            downloadExcel(rowData);
          }}
        />

        <ButtonGeneric
          toolpos="left"
          tooltip="Download Response File"
          extraClass="downloadGenericButton bg-teal-400"
          icon="pi pi-download"
          disabled={!rowData.reportFile}
          method={() => {
            downloadResponseExcel(rowData);
          }}
        />
      </>
    );
  };

  /**
   *
   *
   * @param {*} uploadeddate
   * @return {*}
   */
  // Calculate function for Time Left
  const calculate = (uploadeddate) => {
    const targetDate = new Date(uploadeddate);
    const currentDate = new Date();
    const timeDifference = targetDate.getTime() - currentDate.getTime();
    const daysLeft = 15 + Math.ceil(timeDifference / (1000 * 3600 * 24));
    return daysLeft;
  };
  // Processing Status Body
  const processingStatusBody = (rowData) => {
    return (
      <StageLabel className={`stage-badge status-${rowData.processingStatus}`}>
        {rowData.processingStatus}
      </StageLabel>
    );
  };
  // Upload Body
  const uploadBody = (rowData) => {
    const dateMoment = moment(rowData?.submitTime);

    const formattedDate = dateMoment.format("DD MMM YYYY HH:mm:ss");
    return (
      <div>
        <span>{formattedDate}</span>
      </div>
    );
  };

  const fieldGroupBody = (rowData) => {
    const fieldGroup =
      rowData.reportType === "GROUP_ALL"
        ? "ALL"
        : rowData.reportType === "GROUP_METADATA"
        ? "Meta data"
        : rowData.reportType === "GROUP_DIMENSIONS"
        ? "Dimensions (Product and Package)"
        : rowData.reportType === "GROUP_RANKING"
        ? "Ranking (ALL)"
        : rowData.reportType === "GROUP_RANKING_BOOKS"
        ? "Ranking (Books) "
        : rowData.reportType === "GROUP_OFFERS"
        ? "Pricing (BuyBox and Lowest)"
        : rowData.reportType === "GROUP_IMAGES"
        ? "Images"
        : rowData.reportType === "GROUP_BB_OFFERS"
        ? "Pricing (BuyBox)"
        : rowData.reportType === "GROUP_LP_OFFERS"
        ? "Pricing (Lowest)"
        : "";
    return (
      <span>
        {fieldGroup} ({rowData.marketplace})
      </span>
    );
  };

  /** @type {*} */
  // Columns For Table
  const columns = [
    {
      id: "requestFile.originalFileName",
      field: "requestFile.originalFileName",
      header: "File Name",
      expander: true,
    },
    {
      id: "reportType",
      field: "reportType",
      header: "Fields",
      body: fieldGroupBody,
    },
    {
      id: "submittedBy",
      field: "submittedBy",
      header: "Requested By",
      body: imageWithText,
    },
    {
      id: "submitTime",
      field: "submitTime",
      header: "Request Date",
      body: uploadBody,
    },
    {
      field: "processingStatus",
      header: "Processing Status",
      body: processingStatusBody,
    },
    {
      id: "actionBody",
      field: "actionBody",
      header: "Action",
      body: actionBody,
    },
  ];

  return (
    <>
      <div className="mt-2 mb-2">
        <i className="pi pi-bars mr-2" style={{ fontSize: "1.5rem" }} />
        <StyledLabelBlack>Details of Report Files</StyledLabelBlack>
      </div>
      {/* When Loading false, it will show Report table */}
      {!loading && (
        <>
          <ReportTable data={dataMain} columns={columns} filters={filters} />
          <Pagination
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            lastPage={lastPage}
            sizeList={sizeList}
          />
        </>
      )}
      {/* When Loading true, it will show Report table */}
      {loading && <Loading />}
    </>
  );
};
export default Report;
