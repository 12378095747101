import { Divider } from 'primereact/divider';
import { useEffect, useRef, useState } from 'react';

import { ButtonGeneric } from '../../components/generic/button';
import OptionTab from '../../components/generic/optionTab';
import { StyledLabelBlack } from '../../components/routing/Styled';
import BasicData from './BasicData';
import Category from './Category';
import Contributors from './Contributors';
import Dimension from './Dimension';
import Identifiers from './Identifiers';
import ItemDesc from './ItemDesc';
import PricingDiscount from './PricingDiscount';
import Reputations from './Reputations';

/**
 *
 *
 * @param {*} { productDetail, setAllowBookMain }
 * @return {*}
 */
const ProductDetails = ({
  productDetail,
  setAllowBookMain,
  tableData,
  setProductDetail
}) => {
  /** @type {*Title Of Product Detail Screen} */
  const title = `${
    productDetail?.title?.length > 90
      ? productDetail?.title.substring(0, 90 - 3) + '...'
      : productDetail?.title
  }/${productDetail?.contributors.map((ele, index) => {
    return ele?.role === 'AUTHOR' ? ele.person : '';
  })}`;
  // UseRef For all sections to scroll based on respective selection
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];
  const containerRefs = useRef(null);
  /** @type {*} */
  const [activeTab, setActiveTab] = useState(0);
  const [disabledNext, setDisabledNext] = useState(false);
  const [disabledPrevious, setDisabledPrevious] = useState(false);
  /** @type {*Tab Details(Icon and Label)} */
  const tabs = [
    {
      label: 'Basic Data',
      icon: 'pi pi-info'
    },
    {
      label: 'Item Description',
      icon: 'pi pi-list'
    },
    {
      label: 'Pricing & Offers',
      icon: 'pi pi-dollar'
    },
    {
      label: 'Dimensions',
      icon: 'pi pi-map'
    },
    {
      label: 'Category',
      icon: 'pi pi-comments'
    },
    {
      label: 'Identities',
      icon: 'pi pi-check-square'
    },
    {
      label: 'Reputations',
      icon: 'pi pi-thumbs-up'
    },
    {
      label: 'Contributors',
      icon: 'pi pi-user'
    }
  ];
  // To find Index to move previous or next screen
  const index = tableData.indexOf(productDetail);

  // Inital Settings for Next and Prevous Button
  useEffect(() => {
    index + 1 > tableData.length - 1
      ? setDisabledNext(true)
      : setDisabledNext(false);
    index <= 0 ? setDisabledPrevious(true) : setDisabledPrevious(false);
  }, [index, tableData, productDetail]);
  // To Move to Next Product
  const nextProduct = () => {
    const index = tableData.indexOf(productDetail);
    index + 1 <= tableData.length + 1
      ? setProductDetail(tableData[index + 1])
      : setDisabledNext(true);
    index + 1 <= tableData.length - 1 && setDisabledPrevious(false);
  };
  // To Move to precious Product
  const previousProduct = () => {
    const index = tableData.indexOf(productDetail);
    index > 0
      ? setProductDetail(tableData[index - 1])
      : setDisabledPrevious(true);
    index > 0 && setDisabledNext(false);
  };

  return (
    <>
      <div
        ref={containerRefs}
        className='mt-2 fadeIn flex align-items-center justify-content-between'
        style={{ borderBottom: '1px solid #e5d9d9' }}
      >
        <div className='mb-2 flex align-items-center'>
          <ButtonGeneric
            tooltip='Back To Books'
            extraClass='primaryButton mr-2'
            icon='pi pi-arrow-circle-left'
            toolpos='left'
            method={() => {
              setAllowBookMain(true);
            }}
          />
          <StyledLabelBlack>{title}</StyledLabelBlack>
        </div>
        <div className='mb-2'>
          <ButtonGeneric
            tooltip='Previous'
            extraClass='primaryButton'
            icon='pi pi-chevron-left'
            toolpos='left'
            disabled={disabledPrevious}
            method={() => {
              previousProduct();
            }}
          />
          <ButtonGeneric
            tooltip='Next'
            extraClass='primaryButton'
            icon='pi pi-chevron-right'
            toolpos='bottom'
            disabled={disabledNext}
            method={() => {
              nextProduct();
            }}
          />
        </div>
      </div>

      <div className='p-0 sm:col-12 md:col-12 lg:col-12 fadeIn'>
        <OptionTab
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          sectionRefs={sectionRefs}
          containerRefs={containerRefs}
          number='1'
        />
      </div>
      {/* Reference With Section of all Tabs  */}
      <div className='customProductFlow'>
        <div ref={sectionRefs[0]}>
          <BasicData productDetail={productDetail} />
        </div>
        <Divider />
        <div ref={sectionRefs[1]}>
          <ItemDesc productDetail={productDetail} />
        </div>
        <Divider />
        <div ref={sectionRefs[2]}>
          <PricingDiscount productDetail={productDetail} />
        </div>
        <Divider />

        <div ref={sectionRefs[3]}>
          <Dimension productDetail={productDetail} />
        </div>
        <Divider />
        <div ref={sectionRefs[4]}>
          <Category productDetail={productDetail} />
        </div>
        <Divider />
        <div ref={sectionRefs[5]}>
          <Identifiers productDetail={productDetail} />
        </div>
        <Divider />
        <div ref={sectionRefs[6]}>
          <Reputations productDetail={productDetail} />
        </div>
        <Divider />
        <div ref={sectionRefs[7]}>
          <Contributors productDetail={productDetail} />
        </div>
      </div>
    </>
  );
};
export default ProductDetails;
