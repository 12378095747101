// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Border for Basic Data screen */
.borderBasic {
  border: 2px solid #e5d9d9 !important;
}

.borderCategory {
  border: 2px solid #e5d9d9 !important;
  border-width: 97% !important;
}
`, "",{"version":3,"sources":["webpack://./src/screens/books/books.css"],"names":[],"mappings":"AAAA,iCAAiC;AACjC;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;EACpC,4BAA4B;AAC9B","sourcesContent":["/* Border for Basic Data screen */\n.borderBasic {\n  border: 2px solid #e5d9d9 !important;\n}\n\n.borderCategory {\n  border: 2px solid #e5d9d9 !important;\n  border-width: 97% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
