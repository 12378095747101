import moment from 'moment';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';

import { StyledDataTable } from '../Styled';

/**
 *
 *
 * @param {*} { data, columns, filters }
 * @return {*}
 */
const ReportTable = ({ data, columns, filters }) => {
  const [uploadValue, setUploadValue] = useState('');
  const [dataAfterFilter, setDataAfterFilter] = useState(data);
  /** @type {*} */

  // upload Change
  const onUploadValueChange = (e) => {
    setDataAfterFilter(data);
    const value = e.target.value;
    setUploadValue(value);
    let checking = false;
    const filterData = data.filter((ele, index1) => {
      const dateMoment = moment(ele?.submitTime);
      checking = dateMoment.format('DD MMM YYYY HH:mm:ss').includes(value);

      return checking;
    });

    setDataAfterFilter(filterData);
    value === '' && setDataAfterFilter(data);
  };
  const uploadedDateFilter = (options) => {
    return (
      <InputText
        value={uploadValue}
        onChange={onUploadValueChange}
        placeholder='Search'
      />
    );
  };
  return (
    <StyledDataTable
      value={dataAfterFilter || data}
      dataKey='id'
      rows={10}
      rowsPerPageOptions={[10, 20, 30]}
      currentPageReportTemplate='Showing {first} to {last} of {totalRecords} records'
      responsiveLayout='scroll'
      filters={filters}
      filterDisplay='row'
    >
      {columns.map((column) => {
        return (
          <Column
            key={column.id}
            field={column.field}
            header={column.header}
            body={column.body}
            sortable={column.id !== 'actionBody'}
            showFilterMenu={false}
            filterMenuStyle={{ width: '12rem' }}
            filter={column.id !== 'actionBody'}
            filterPlaceholder='Search'
            filterElement={
              column.header === 'Uploaded Date' && uploadedDateFilter
            }
          />
        );
      })}
    </StyledDataTable>
  );
};
export default ReportTable;
