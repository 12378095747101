import { Dropdown } from 'primereact/dropdown';

import {
  ButtonGeneric,
  PaginationButton
} from '../../components/generic/button';
import { InputNum } from '../../components/generic/Styled';
import { StyledLabelBlack } from '../../components/routing/Styled';

const Pagination = ({ page, setPage, size, setSize, lastPage, sizeList }) => {
  return (
    <div className='flex justify-content-end align-items-center mb-2 mt-2 pb-2'>
      <PaginationButton
        tooltip='First Page'
        toolpos='left'
        icon='-angle-double-left'
        method={() => {
          setPage(1);
        }}
        disabled={page === 1}
        extraClass='border-round-xl mr-2 '
      />
      <PaginationButton
        icon='-angle-left'
        method={() => {
          setPage(page - 1);
        }}
        disabled={page === 1}
        extraClass='border-round-xl mr-2'
      />

      <ButtonGeneric
        style={{
          minHeight: '30px',
          minWidth: '30px',
          color: '#fff',
          margin: '0 !important'
        }}
        label={page}
        extraClass='border-circle flex justify-content-center align-items-center pl-2'
      />
      <PaginationButton
        icon='-angle-right'
        method={() => {
          setPage(page + 1);
        }}
        disabled={page === lastPage || lastPage === 0}
        extraClass='border-round-xl ml-2'
      />
      <PaginationButton
        tooltip='Last Page'
        icon='-angle-double-right'
        method={() => {
          setPage(lastPage);
        }}
        disabled={page === lastPage || lastPage === 0}
        extraClass='border-round-xl mr-2'
      />
      <div className='mr-2'>
        <StyledLabelBlack
          style={{ fontSize: '13px', fontWeight: '400' }}
          className='mr-2'
        >
          Go to Page:
        </StyledLabelBlack>
        <InputNum
          value={page}
          onValueChange={(e) =>
            setPage(e.value <= 0 || e.value > lastPage ? 1 : e.value)}
          showButtons
        />
      </div>
      <Dropdown
        value={size}
        onChange={(e) => {
          setSize(e.value);
        }}
        options={sizeList}
        optionLabel='name'
        editable
        placeholder='Select a Size'
      />
    </div>
  );
};
export default Pagination;
