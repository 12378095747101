import { useState } from 'react';

import BookMainFile from './BookMainFile';

/**
 *
 *
 * @return {*}
 */
const Books = () => {
  /** @type {*} */
  const [allowBookMain, setAllowBookMain] = useState(true);
  /** @type {*} */
  const [productDetail, setProductDetail] = useState({});
  const [tableData, setTableData] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  return (
    <>
      <BookMainFile
        setAllowBookMain={setAllowBookMain}
        setProductDetail={setProductDetail}
        productDetail={productDetail}
        setTableData={setTableData}
        tableData={tableData}
        allowBookMain={allowBookMain}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
      />
    </>
  );
};
export default Books;
