import { Fieldset } from 'primereact/fieldset';

import dimension from '../../assets/png/dimensionred.png';
import dimensionImg from '../../assets/png/dimensions.png';
import { StyledLabelBlack } from '../../components/routing/Styled';

const Dimension = ({ productDetail }) => {
  const dimensionTemp = productDetail?.physical?.packaging?.dimension;

  const packageTemp = productDetail?.physical?.packaging;
  // Legend Template
  const legendTemplate = (title, icon) => {
    return (
      <div className='flex align-items-center'>
        <span className='mr-2'>
          <img src={icon} alt='' />
        </span>
        <span>{title}</span>
      </div>
    );
  };
  const contentPackage = [
    {
      name: 'Is Battery Included',
      value: `${packageTemp?.isBatteryIncluded || 'NA'}`
    },
    {
      name: 'Packaging Material',
      value: `${packageTemp?.packagingMaterial || 'NA'}`
    },
    {
      name: 'Packaging Notes',
      value: `${packageTemp?.packagingNotes || 'NA'}`
    },
    {
      name: 'Packaging Condition',
      value: `${packageTemp?.packagingCondition || 'NA'}`
    },
    {
      name: 'Packaging Quantity',
      value: `${packageTemp?.packagingQuantity || 'NA'}`
    },
    {
      name: 'Packer Contact Details',
      value: `${packageTemp?.packerContactDetails || 'NA'}`
    }
  ];
  // Dimension Value
  const dimensionValue = () => {
    if (dimensionTemp?.length?.value) {
      return `${dimensionTemp?.length?.value}*${dimensionTemp?.width?.value}*
  ${dimensionTemp?.height?.value}(${dimensionTemp?.height?.unit})`;
    } else {
      return 'NA';
    }
  };
  return (
    <div className='grid'>
      {/* Portion for Dimension */}
      <div className='md:col-12 sm:col-12 mt-2 p-0 pl-2 pr-4'>
        <div className='text-left'>
          <Fieldset
            legend={legendTemplate('Dimensions,Weight & Package', dimension)}
          >
            <div className='p-3 grid'>
              <div className='md:col-12 col-12'>
                <div className='flex'>
                  <div>
                    <img src={dimensionImg} alt='' />
                  </div>
                  <div className='ml-2' style={{ marginTop: '44px' }}>
                    <StyledLabelBlack style={{ color: '#bc3232' }}>
                      {`${dimensionValue()}/Weight:${
                        dimensionTemp?.weight?.value
                          ? `${dimensionTemp?.weight?.value}(${dimensionTemp?.weight?.unit})`
                          : 'NA'
                      } `}
                    </StyledLabelBlack>
                  </div>
                </div>
              </div>
              {contentPackage.map((val) => {
                return (
                  <div className='md:col-2 col-12' key={val.name}>
                    <StyledLabelBlack>{val.name}</StyledLabelBlack>
                    <div className='mt-1 flex '>
                      <div> {val.value}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Fieldset>
        </div>
      </div>
    </div>
  );
};
export default Dimension;
