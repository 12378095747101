import {
  ActionButton,
  CancelFlatButton,
  ExpandButton,
  FlatButton,
  PagButton,
  RefreshButton
} from './Styled';

// Round Button
export const ButtonGeneric = ({
  icon,
  shape,
  color,
  method,
  extraClass,
  tooltip,
  style,
  label,
  toolpos,
  disabled
}) => {
  return (
    <ActionButton
      tooltip={tooltip}
      placeholder='Left'
      label={label}
      style={style}
      tooltipOptions={{ position: toolpos }}
      icon={`pi pi${icon}`}
      className={`p-button${shape} ${color} mr-1 ml-1  ${extraClass}`}
      onClick={method}
      disabled={disabled}
    />
  );
};
// Refresh Button
export const ButtonRefresh = ({
  icon,
  shape,
  color,
  method,
  extraClass,
  tooltip,
  style,
  label,
  toolpos,
  disabled
}) => {
  return (
    <RefreshButton
      tooltip={tooltip}
      placeholder='Left'
      label={label}
      style={style}
      tooltipOptions={{ position: toolpos }}
      icon={`pi pi${icon}`}
      className={`p-button${shape} ${color} mr-1 ml-1  ${extraClass}`}
      onClick={method}
      disabled={disabled}
    />
  );
};
// Button Used In Pagination
export const PaginationButton = ({
  icon,
  shape,
  color,
  method,
  extraClass,
  tooltip,
  style,
  label,
  toolpos,
  disabled
}) => {
  return (
    <PagButton
      tooltip={tooltip}
      placeholder='Left'
      label={label}
      style={style}
      tooltipOptions={{ position: toolpos }}
      icon={`pi pi${icon}`}
      className={`p-button${shape} ${color} mr-1 ml-1  ${extraClass}`}
      onClick={method}
      disabled={disabled}
    />
  );
};

// Button for Expanding && Collapse
export const ButtonExpand = ({ icon, shape, color, method, extraClass }) => {
  return (
    <ExpandButton
      icon={`pi pi${icon}`}
      className={`p-button${shape} ${color} mr-1 ml-1  ${extraClass}`}
      onClick={method}
    />
  );
};
// Flat Button
export const ButtonFlat = ({
  label,
  icon,
  color,
  method,
  color2,
  extraClass,
  style,
  disabled,
  icon1
}) => {
  return (
    <FlatButton
      label={label}
      icon={icon1}
      style={style}
      disabled={disabled}
      className={`p-button-sm  p-button-text${color} ${color2} ${extraClass} ${icon}`}
      onClick={method}
    />
  );
};
// Cancel Button
export const ButtonCancel = ({
  label,
  icon,
  color,
  method,
  color2,
  extraClass,
  severity
}) => {
  return (
    <CancelFlatButton
      label={label}
      className={`p-button-sm p-button-text${color} ${color2} ${extraClass} ${icon}`}
      onClick={method}
    />
  );
};
