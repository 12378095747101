import { Fieldset } from 'primereact/fieldset';

import contributorImg from '../../assets/png/contributor.png';
import { StyledLabelBlack } from '../../components/routing/Styled';

const Contributors = ({ productDetail }) => {
  // Legend Template
  const legendTemplate = (title, icon) => {
    return (
      <div className='flex align-items-center'>
        <span className='mr-2'>
          <img src={icon} alt='' />
        </span>
        <span>{title}</span>
      </div>
    );
  };
  return (
    <div className='pr-3 mb-2'>
      <Fieldset
        legend={legendTemplate('Contributors', contributorImg)}
        style={{ minHeight: '100px' }}
      >
        <div className='grid'>
          <div className='md:col-12 lg:col-12 sm:col-12'>
            <div className='text-left'>
              <div className='p-3 grid'>
                {productDetail?.contributors?.map((val) => {
                  const person = val?.person || 'NA';
                  const formattedPerson = person?.endsWith('.')
                    ? person
                    : `${person}.`;

                  return (
                    <div key={val.person} className='md:col-4 col-12'>
                      <StyledLabelBlack>{val.role}</StyledLabelBlack>
                      <div className='mt-1'>{formattedPerson}</div>{' '}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Fieldset>
    </div>
  );
};
export default Contributors;
