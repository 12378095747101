import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import { useEffect, useState } from "react";

import importJson from "../../assets/json/import.json";
import AnimePlayer from "../../components/generic/animePlayer";
import FileUploadComponent from "../../components/generic/fileUpload";
import {
  CardNoBackground,
  CardWithBackground,
  StyledCustomCard,
  StyledLabelBlack,
} from "../../components/routing/Styled";

/**
 *
 *
 * @return {*}
 */
const Upload = ({ setActiveTab }) => {
  // Variable to hold Request Data
  const [request, setRequest] = useState("REFRESH_DATA");
  // Variable to hold Data Type
  // const [dataType, setDataType] = useState('GROUP_ALL');
  const [dataType, setDataType] = useState("GROUP_METADATA");
  // Variable to hold Market Place
  // const [marketPlace, setMarketPlace] = useState(["A21TJRUUN4KGV"]);
  const [marketPlace, setMarketPlace] = useState("A21TJRUUN4KGV");
  // Variable to hold Request Data Option
  const [requestOption, setRequestOption] = useState([]);
  // Variable to hold Data Type Option
  const [dataTypeOption, setDataTypeOption] = useState([]);
  // Variable to hold Market Place Option
  const [marketOption, setMarketOption] = useState([]);
  // To set Request Option
  useEffect(() => {
    setRequestOption([
      { value: "REFRESH_DATA", label: "Fetch" },
      { value: "GENERATE_REPORT", label: "Generate Report" },
      {
        value: "REFRESH_DATA_AND_GENERATE_REPORT",
        label: "Fetch & Generate Report",
      },
    ]);
    // Fetch dataTypeOption data
    fetch(
      process.env.REACT_APP_APIIMPORTURL +
        "/v1/fieldgroups?page=0&size=50&sortby=status&sortOrder=ASC&status=ACTIVE",
      {
        headers: {
          Accept: "application/json;charset=UTF-8",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data here
        const updateddata = [];

        data._embedded.fieldGroupVOList.map((item) => {
          return updateddata.push({
            value: item.code,
            label: item.description,
          });
        });
        setDataTypeOption(updateddata);
      })
      .catch((error) => {
        // Handle any errors here
        console.error("Error fetching dataTypeOption:", error);
      });
    // Fetch marketOption data
    fetch(
      process.env.REACT_APP_APIIMPORTURL +
        "/v1/marketplaces/source/AMZ?page=0&size=25&sortby=status&sortOrder=ASC&status=ACTIVE",
      {
        headers: {
          Accept: "application/json;charset=UTF-8",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data here
        const updateddata = [];
        data._embedded.marketplaceVOList.map((item) => {
          return updateddata.push({ value: item.code, label: item.country + '(' + item.countryCode + ')' });
        });
        setMarketOption(updateddata);
      })
      .catch((error) => {
        // Handle any errors here
        console.error("Error fetching dataTypeOption:", error);
      });
  }, []);

  // marketOption to generate radio button
  const reqOption = (option, check, update) => {
    return option.map((req) => {
      return (
        <div key={req.value} style={{ fontSize: "12px" }}>
          <RadioButton
            value={req.value}
            name={req.label}
            onChange={(e) => update(e.value)}
            checked={check === req.value}
            className="mt-2 mr-2  "
          />
          <span className="text-center">{req.label}</span>
        </div>
      );
    });
  };
  /**
   *market place selection
   *
   * @param {*} e
  
  const onmarketPlaceChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setMarketPlace([...marketPlace, value]);
    } else {
      const updatedItems = marketPlace.filter((item) => item !== value);
      setMarketPlace(updatedItems);
    }
  };
 */
  /**
   *marketOption to generate checkbox
   *
   * @param {*} option
   * @param {*} check
   * @param {*} update
   * @return {*}
   */
  const reqMarketOption = (option, check, update) => {
    return option.map((req) => {
      return (
        <div style={{ fontSize: "12px" }} key={req.value}>
          <RadioButton
            value={req.value}
            name={req.label}
            onChange={(e) => update(e.value)}
            checked={check === req.value}
            className="mt-2 mr-2  "
          />
          {/*
          <Checkbox
            inputId={`checkbox_${req.value}`}
            name={req.label}
            value={req.value}
            onChange={onmarketPlaceChange}
            checked={marketPlace.includes(req.value)}
            className='mt-2 mr-2 '
          />
          */}
          <span>{req.label}</span>
        </div>
      );
    });
  };
  return (
    <>
      <div className="grid mt-1">
        <StyledCustomCard className="col-12 sm:col:4 md:col-4 lg:col-4">
          <CardWithBackground>
            <StyledLabelBlack>Request Type</StyledLabelBlack>
          </CardWithBackground>
          <CardNoBackground>
            {reqOption(requestOption, request, setRequest)}
          </CardNoBackground>
        </StyledCustomCard>

        <StyledCustomCard className="col-12 sm:col:4 md:col-4 lg:col-4">
          <CardWithBackground>
            <StyledLabelBlack>Market Place</StyledLabelBlack>
          </CardWithBackground>
          <CardNoBackground>
            {reqMarketOption(marketOption, marketPlace, setMarketPlace)}
          </CardNoBackground>
        </StyledCustomCard>

        <StyledCustomCard className="col-12 sm:col:4 md:col-4 lg:col-4">
          <CardWithBackground>
            <StyledLabelBlack>Data Type</StyledLabelBlack>
          </CardWithBackground>
          <CardNoBackground>
            {reqOption(dataTypeOption, dataType, setDataType)}
          </CardNoBackground>
        </StyledCustomCard>
      </div>
      <div className="grid mt-1">
        <div className="col-12 sm:col:6 md:col-6 lg:col-6">
          <div className="text-center">
            <FileUploadComponent
              dataType={dataType}
              request={request}
              marketPlace={marketPlace}
              setActiveTab={setActiveTab}
            />
          </div>
        </div>
        <div className="col-12 sm:col:6 md:col-6 lg:col-6">
          <div>
            <AnimePlayer src={importJson} style={{ height: "240px" }} />
          </div>
        </div>
      </div>
    </>
  );
};
export default Upload;
