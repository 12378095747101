import { filter } from 'lodash';
import { Badge } from 'primereact/badge';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { useCallback, useEffect, useRef, useState } from 'react';

import Loading from '../../components/generic/loading';
import { PanelHeading } from '../../components/routing/Styled';
import Pagination from '../books/Pagination';
import { exportData } from './download';

/**
 *
 *
 * @return {*}
 */
const UploadedStageFetched = ({ load }) => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [lastPage, setLastPage] = useState(1);
  const sizeList = [
    { name: 20, value: 20 },
    { name: 30, value: 30 },
    { name: 40, value: 40 }
  ];
  /** @type {*} */
  const [selectedRow, setSelectedRow] = useState(null);

  /** @type {*} */
  const [costData, setCostData] = useState([]);

  /** @type {*} */
  const [drilldownData, setDrilldownData] = useState([]);

  /** @type {*} */
  const [loading, setLoading] = useState(false);
  /** @type {*} */
  const toast = useRef(null);

  /** @type {*} */
  const fetchCostData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetch(
        process.env.REACT_APP_APIIMPORTURL +
          `/v1/requests/import?page=${page - 1}&size=${size}`,
        {
          headers: {
            Accept: 'application/json;charset=UTF-8'
          }
        }
      );
      const data = await response.json();
      setLastPage(data?.page?.totalPages);
      setLoading(false);
      const updatedData = data._embedded.responseVOList;

      const filteredData = updatedData.filter((item, index) => {
        return item.stats?.fetch;
      });
      setCostData(filteredData);
    } catch (error) {
      console.error('Error fetching cost data:', error);
    }
  }, [size, page]);

  /** @type {*} */
  const fetchDrilldownData = useCallback(async () => {
    if (selectedRow) {
      try {
        const response = await fetch(
          process.env.REACT_APP_APIIMPORTURL +
            '/v1/requests/' +
            selectedRow.id +
            '/items?page=0&size=10',
          {
            headers: {
              Accept: 'application/json;charset=UTF-8'
            }
          }
        );
        const data = await response.json();
        const updatedData = data._embedded.importRequestItemVOList;
        const filteredData = filter(updatedData, (item) => item?.error);
        setDrilldownData(filteredData);
      } catch (error) {
        console.error('Error fetching drilldown data:', error);
      }
    }
  }, [selectedRow]);

  useEffect(() => {
    fetchDrilldownData();
  }, [fetchDrilldownData]);
  useEffect(() => {
    fetchCostData();
  }, [fetchCostData, load]);
  // File Name Body
  const fileNameBody = (rowData) => {
    return <span>{rowData?.requestFile?.originalFileName}</span>;
  };
  // Total records body
  const totalRecordsBody = (rowData) => {
    return <Badge value={rowData?.stats?.totalCount || 0} severity='info' />;
  };
  // Success Count Body
  const successCountBody = (rowData) => {
    return (
      <Badge
        value={rowData?.stats?.fetch?.successCount || 0}
        severity='success'
      />
    );
  };
  // Failure Count Body
  const failureCountBody = (rowData) => {
    return (
      <Badge
        value={rowData?.stats?.fetch?.failureCount || 0}
        severity='danger'
      />
    );
  };

  // Time Taken body
  const timeTakenBody = (rowData) => {
    const timeTaken = rowData?.stats?.fetch?.timeTaken;
    const unit = timeTaken >= 60000 ? 'mins' : 'min';
    const formattedTime = timeTaken
      ? `${(timeTaken / 60000).toFixed(0)} ${unit}`
      : '';
    return <span>{formattedTime}</span>;
  };
  /** @type {*} */
  const columns = [
    {
      field: 'requestFile.originalFileName',
      header: 'File Name',
      expander: true,
      body: fileNameBody
    },
    {
      field: 'stats.totalCount',
      header: 'Total Records',
      body: totalRecordsBody
    },
    {
      field: 'stats.val.successCount',
      header: 'Success',
      body: successCountBody
    },
    {
      field: 'stats.val.failureCount',
      header: 'Failed',
      body: failureCountBody
    },
    {
      field: 'stats.val.timeTaken',
      header: 'Time Taken',
      body: timeTakenBody
    }
  ];
  // Refresh
  useEffect(() => {
    setSelectedRow(null);
  }, [load]);
  /** @type {*} */
  const drilledColumns = [
    {
      field: 'type',
      header: 'Type'
    },
    {
      field: 'id',
      header: 'ID'
    },
    {
      field: 'marketplace',
      header: 'Market Place'
    },
    {
      field: 'remarks',
      header: 'Remarks'
    }
  ];

  /**
   *
   *
   * @param {*} event
   */
  const onRowClick = (event) => {
    event.data.stats?.fetch?.failureCount
      ? setSelectedRow(event.data)
      : toast.current.show({
        severity: 'error',
        summary: 'No Failed Records',
        detail: 'There is no Failed Records',
        life: 3000
      });
  };

  /**
   *
   *
   * @param {*} drilldownData
   * @param {*} datavalidexport
   */
  const exportDetail = (drilldownData, datavalidexport) => {
    if (drilldownData.length !== 0) {
      const formattedData = drilldownData.map((item) => {
        // Format the data as needed for Excel export, for example:
        return {
          Type: item.type,
          ID: item.id,
          Marketplace: item.marketplace,
          Status: item.status
          // Add more fields as needed
        };
      });
      exportData(drilldownData, datavalidexport, formattedData);
    }
  };

  /** @type {*} */
  const exportButtonRef = useRef(null);

  return (
    <div>
      <Toast ref={toast} />
      {!loading && (
        <>
          <DataTable
            value={costData}
            dataKey='id'
            rows={15}
            selectionMode='single'
            selection={selectedRow}
            onSelectionChange={(e) => setSelectedRow(e.value)}
            onRowClick={onRowClick}
            paginatorTemplate='CurrentPageReport
        FirstPageLink
        PrevPageLink
        PageLinks
        NextPageLink
        LastPageLink
        RowsPerPageDropdown'
            currentPageReportTemplate='Showing {first} to {last} of {totalRecords} entries'
            rowsPerPageOptions={[15, 20, 30]}
          >
            {columns.map((col) => (
              <Column
                key={col.field}
                field={col.field}
                header={col.header}
                body={col.body}
                sortable
              />
            ))}
          </DataTable>{' '}
          <Pagination
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            lastPage={lastPage}
            sizeList={sizeList}
          />
        </>
      )}
      {loading && <Loading />}
      {selectedRow?.stats?.fetch?.failureCount > 0 && (
        <div>
          <PanelHeading className='p-d-flex p-ai-center text-color text-base font-medium'>
            <span>
              <i
                ref={exportButtonRef}
                title='Export All'
                className='pi pi-file-excel mr-2 '
                style={{ fontSize: 'inherit !important' }}
              />
              Failed Records Details
              <i
                ref={exportButtonRef}
                title='Export All'
                className='pi pi-file-export ml-3 text-red-500 text-lg cursor-pointer '
                onClick={() => exportDetail(drilldownData, 'datavalidexport')}
                style={{ fontSize: 'inherit !important' }}
              />
            </span>
          </PanelHeading>
          {drilldownData && (
            <DataTable
              value={drilldownData}
              dataKey='id'
              rows={5}
              paginator
              paginatorTemplate='CurrentPageReport
          FirstPageLink
          PrevPageLink
          PageLinks
          NextPageLink
          LastPageLink
          RowsPerPageDropdown'
              currentPageReportTemplate='Showing {first} to {last} of {totalRecords} entries'
              rowsPerPageOptions={[5, 10, 20]}
            >
              {drilledColumns.map((col) => (
                <Column
                  key={col.field}
                  field={col.field}
                  header={col.header}
                  sortable
                />
              ))}
            </DataTable>
          )}
        </div>
      )}
    </div>
  );
};

export default UploadedStageFetched;
