import { Fieldset } from 'primereact/fieldset';
import { useEffect, useState } from 'react';

import identitiesImg from '../../assets/png/identities.png';
import { StyledLabelBlack } from '../../components/routing/Styled';

const Identifiers = ({ productDetail }) => {
  const [identityData, setIdentityData] = useState([]);
  const content = [
    { name: 'Status', value: `${identityData?.status || 'NA'}` },
    { name: 'Resource', value: `${identityData?.resource || 'NA'}` },
    { name: 'Source', value: `${identityData?.ext?.source || 'NA'}` },
    {
      name: `${identityData?.ext?.type || 'ASIN'}`,
      value: `${identityData?.ext?.id || 'NA'}`
    }
  ];
  const aliases = identityData?.aliases || [];
  useEffect(() => {
    fetch(
      process.env.REACT_APP_APIPRODUCTURL +
        `/v1/identities/source/AMZ/product/${productDetail?.primaryId?.id}`,
      {
        headers: {
          Accept: 'application/json;charset=UTF-8'
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setIdentityData(data?._embedded?.idVOList[0]);
      })
      .catch(() => {
        // Handle any errors here
        // console.error('Error fetching dataTypeOption:', error);
      });
  }, [productDetail?.primaryId?.id]);
  // Legend Template
  const legendTemplate = (title, icon) => {
    return (
      <div className='flex align-items-center'>
        <span className='mr-2'>
          <img src={icon} alt='' />
        </span>
        <span>{title}</span>
      </div>
    );
  };
  return (
    <div className='pr-3'>
      {' '}
      <Fieldset
        legend={legendTemplate('Identities Info', identitiesImg)}
        style={{ minHeight: '100px' }}
      >
        <div style={{ width: '97.5%', marginLeft: '8px' }} className='grid '>
          {content.map((val) => {
            return (
              <div className='md:col-3 col-12' key={val.name}>
                <StyledLabelBlack className='mr-2'>
                  {val.name}:
                </StyledLabelBlack>
                {val.value}
              </div>
            );
          })}

          {aliases?.map((val) => {
            return (
              <div key={val.type} className='md:col-3 col-12'>
                <StyledLabelBlack className='mr-2'>
                  {val.type}:
                </StyledLabelBlack>
                {val.id}
              </div>
            );
          })}
        </div>
      </Fieldset>
    </div>
  );
};
export default Identifiers;
